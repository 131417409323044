export default function LaCardOffline() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 13.1094H19.5"
        stroke="#003ACD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 10.7808V17.9308C19.47 20.7808 18.69 21.5008 15.72 21.5008H6.28003C3.26003 21.5008 2.5 20.7508 2.5 17.7708V10.7808C2.5 8.08078 3.13 7.21078 5.5 7.07078C5.74 7.06078 6.00003 7.05078 6.28003 7.05078H15.72C18.74 7.05078 19.5 7.80078 19.5 10.7808Z"
        stroke="#003ACD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 7.23V14.22C22.5 16.92 21.87 17.79 19.5 17.93V10.78C19.5 7.8 18.74 7.05 15.72 7.05H6.28003C6.00003 7.05 5.74 7.06 5.5 7.07C5.53 4.22 6.31003 3.5 9.28003 3.5H18.72C21.74 3.5 22.5 4.25 22.5 7.23Z"
        stroke="#003ACD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 18.3105H7.46997"
        stroke="#003ACD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.60938 18.3105H13.0494"
        stroke="#003ACD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
