import { createSlice } from "@reduxjs/toolkit";

type TournamentSlice = {
  id: string;
  tournamentInvitation: {
    tournamentId: string;
    notificationId: string;
  };
  updateTeamStatusInfo: {
    tournamentId: string;
    teamId: string;
    teamName: string;
    registrationFee: string;
    status: "invited" | "processingPayment";
  };
};

const initialState: TournamentSlice = {
  id: "",
  tournamentInvitation: {
    tournamentId: "",
    notificationId: "",
  },
  updateTeamStatusInfo: {
    tournamentId: "",
    teamId: "",
    teamName: "",
    registrationFee: "",
    status: "invited",
  },
};

const tournamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    setTournament: (state, action) => {
      state.id = action.payload;
    },
    setTournamentInvitation: (state, action) => {
      state.tournamentInvitation = action.payload;
    },

    setUpdateTeamStatusInfo: (state, action) => {
      state.updateTeamStatusInfo = action.payload;
    },
  },
});

export const { setTournamentInvitation, setUpdateTeamStatusInfo } =
  tournamentSlice.actions;
export default tournamentSlice.reducer;
