import { useRef, useState } from "react";
import { FaEllipsisVertical } from "react-icons/fa6";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { useAppDispatch } from "../../redux/app/hooks";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import { setUpdateTeamStatusInfo } from "../../redux/features/tournament/tournamentSlice";

function ManageTeamsActionDropDown({
  tournamentId,
  teamId,
  teamName,
  registrationFee,
  status,
  registered,
}: {
  tournamentId: string;
  teamId: string;
  teamName: string;
  registrationFee: string;
  registered: boolean;
  status?: "registered" | "invited" | "paymentProcessing";
}) {
  const [showMenu, setShowMenu] = useState(false);
  const elRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(elRef, () => {
    setShowMenu(false);
  });
  const dispatch = useAppDispatch();

  function handleUpdateStatus() {
    if (!status || status === "registered") {
      return;
    }
    setShowMenu(false);
    dispatch(
      setUpdateTeamStatusInfo({
        tournamentId,
        teamId,
        teamName,
        registrationFee,
        status,
      })
    );
    dispatch(setModalToDisplay("updateTeamStatus"));
  }
  return (
    <div className="relative flex items-center justify-center">
      <button className="flex h-8 w-8 items-center justify-center  rounded-lg border-[0.7px] border-[#BBBBBB]">
        <FaEllipsisVertical
          className="top-[100px] h-4 w-4 text-[#1B1818] lg:bottom-0"
          onClick={() => setShowMenu(true)}
        />
        {showMenu && (
          <ul
            ref={elRef}
            className="absolute z-50 flex min-w-[150px] flex-col rounded-[4px] border border-black bg-white py-2 text-left"
          >
            <li className=" border-black p-2 hover:border-l-2 hover:bg-[#E6E6E6]">
              Delete Team
            </li>
            {!registered && (
              <li
                className="border-black p-2 hover:border-l-2 hover:bg-[#E6E6E6]"
                onClick={handleUpdateStatus}
              >
                Update Status
              </li>
            )}
            <li className="border-black p-2 hover:border-l-2 hover:bg-[#E6E6E6]">
              Player Transfer
            </li>
          </ul>
        )}
      </button>
    </div>
  );
}

export default ManageTeamsActionDropDown;
