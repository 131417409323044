import { Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/app/hooks";
import { RootState } from "../redux/app/store";
import {
  ToastCopied,
  ToastError,
  ToastErrorText,
  ToastSuccess,
} from "../components/ui/toast";

export default function LayoutWrapper() {
  const toastSuccess = useAppSelector(
    (state: RootState) => state.displayModal.toastSuccess
  );
  const toastError = useAppSelector(
    (state: RootState) => state.displayModal.toastError
  );
  const toastCopied = useAppSelector(
    (state: RootState) => state.displayModal.toastCopied
  );
  const toastErrorText = useAppSelector(
    (state: RootState) => state.displayModal.toastErrorText
  );

  return (
    <>
      <Outlet />
      {toastSuccess && <ToastSuccess />}
      {toastError && <ToastError />}
      {toastCopied && <ToastCopied />}
      {toastErrorText && <ToastErrorText />}
    </>
  );
}
