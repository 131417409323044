import type { ReactElement } from "react";
import { LaClose, LaPlusIcon } from "../svg-icons";
import { Button, Spinner } from "../ui";
import formatToNaira from "../../utils/formatPrice";
import { CreateTeamsForCompetitionForm } from "../../pages/tournament/CreateTeamsFormForCompetition";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useCreatePlayersMutation } from "../../redux/services/playerService";
import { useNavigate } from "react-router-dom";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import React from "react";
import { ConfirmPopup } from "../modals";
import { nanoid } from "nanoid";

export interface CreateTeamsForCompetitionProps {
  playersInfo: CreateTeamsForCompetitionForm[];
  setPlayersInfo: React.Dispatch<
    React.SetStateAction<CreateTeamsForCompetitionForm[]>
  >;
  selectedCompetitionForTeamId: string;
  selectedCompetitionForTeam: SelectedCompetition | null;
}

export default function CreateTeamsForCompetition({
  playersInfo,
  setPlayersInfo,
  selectedCompetitionForTeamId,
  selectedCompetitionForTeam,
}: CreateTeamsForCompetitionProps): ReactElement {
  const navigate = useNavigate();
  const [createPlayers, { isLoading }] = useCreatePlayersMutation();
  const dispatch = useAppDispatch();
  const teamProfile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "team");
  const teamId = teamProfile?.profile;
  const teamName = teamProfile?.name;
  // const { data } = useGetTeamQuery({ id: teamId as string });
  const [modalToDisplay, setModalToDisplay] = React.useState<
    "incomplete" | "success" | ""
  >("");

  const noOfPlayersAdded = selectedCompetitionForTeam?.team.players.length ?? 0;
  const requiredPlayers = selectedCompetitionForTeam?.noOfPlayers ?? 0;

  function handleClick() {
    if (noOfPlayersAdded + playersInfo.length < requiredPlayers)
      return setModalToDisplay("incomplete");
    return handleCreatePlayers();
  }

  const handleCreatePlayers = () => {
    const refinedPlayersInfo = playersInfo.map((player) => {
      if (player.phoneNumber === "") {
        return {
          ...player,
          phoneNumber: nanoid(),
        };
      }
      return player;
    });
    createPlayers({
      players: refinedPlayersInfo,
      teamId: teamId,
      paymentStatus: "pending",
      tournamentId: selectedCompetitionForTeamId,
    })
      .unwrap()
      .then(() => {
        setModalToDisplay("success");
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        if (err.data.code === 409) {
          dispatch(setToastErrorText("username already exists"));
          return;
        }
        dispatch(setToastErrorText(err.data.message));
      });
  };

  return (
    <>
      <div className="fixed bottom-0 right-0 z-[60] flex w-full flex-col rounded border-t border-grey-light bg-white px-4 py-4 shadow lg:w-[82%] lg:rounded-2xl lg:px-20 lg:py-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <h3 className="flex items-center gap-2 font-medium lg:text-2xl">
              Register
              <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-blue-lighter text-xs">
                {playersInfo.length}
              </span>
              players
            </h3>
          </div>
          <button
            onClick={() => {
              setPlayersInfo([]);
            }}
          >
            <LaClose />
          </button>
        </div>
        <Button
          role="organizer"
          intent={"primary"}
          className="mt-4 items-center gap-1 lg:flex"
          onClick={handleClick}
        >
          {isLoading ? <Spinner /> : "Register Players"}
        </Button>
      </div>
      {modalToDisplay === "incomplete" && (
        <ConfirmPopup
          heading={"Incomplete team"}
          type="warning"
          description={`${
            noOfPlayersAdded + playersInfo.length
          } out of ${requiredPlayers} remaining players will be added. Do you want to proceed?`}
          warning="Players should be complete"
          buttons={[
            {
              text: "Add more teams",
              handleClick: () => setModalToDisplay(""),
              class: "primary",
              icon: <LaPlusIcon stroke="#fff" />,
            },
            {
              text: "Yes, I want to proceed",
              handleClick: () => {
                setModalToDisplay("");
                handleCreatePlayers();
              },
              class: "tertiary",
            },
          ]}
        />
      )}
      {modalToDisplay === "success" && (
        <ConfirmPopup
          heading={"Success!"}
          type="success"
          description={`Your Player profiles are ready. They will be notified via contact number you provided.`}
          buttons={[
            {
              text: "Got it",
              handleClick: () => {
                setPlayersInfo([]);
                navigate(`/competitions/${selectedCompetitionForTeamId}`);
              },
              class: "primary",
            },
          ]}
        />
      )}
    </>
  );
}
