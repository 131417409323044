import * as Icons from "../../components/svg-icons";
import { useNavigate, Navigate, useParams, Link } from "react-router-dom";
import { Spinner } from "../../components/ui";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import { RootState } from "../../redux/app/store";
import {
  TournamentInfo,
  TournamentNavigation,
  TournamentTab,
} from "../../components/tournament";
import { useGetTournamentFixturesQuery } from "../../redux/services/matchService";
import reduceFixtures from "../../utils/reduceFixtures";
import { copyToClipboard } from "../../utils/copyToClipboard";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { ConfirmPopup } from "../../components/modals";
import { useState } from "react";
import HelmetComponent from "../../components/HelmetComponent";
import PreviewSpotLightModal from "../../components/modals/PreviewSpotLightModal";
import UpdateTeamStatusModal from "../../components/modals/UpdateTeamStatusModal";

function Tournament() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const modalToDisplay = useAppSelector(
    (state: RootState) => state.displayModal.modalToDisplay
  );
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const [_, handleCopy] = useCopyToClipboard();
  const userRole = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const { tournamentId } = useParams();
  const {
    data: tournamentData,
    isLoading: tournamentLoading,
    isError,
  } = useGetTournamentQuery({
    id: tournamentId as string,
  });
  const { data: fixtures, isLoading: fixturesLoading } =
    useGetTournamentFixturesQuery({
      id: tournamentId,
    });

  const isTournamentOwner =
    tournamentData?.author === myId && userRole === "organizer";

  const isInTournament = tournamentData?.teams.find((teamInfo) => {
    const team = teamInfo.team as Team;
    return team?.author === myId;
  });

  const hasRegisteredTeams =
    isInTournament &&
    (tournamentData?.noOfPlayers ?? 0) < (isInTournament?.players.length ?? 0);
  const [showTeamConfirmation, setShowTeamConfirmation] = useState(
    !hasRegisteredTeams
  );
  const noOfPlayersInTeam = isInTournament?.players?.length;

  // open graph data
  const ogData = {
    title: "Leagues Arena - Tournament",
    description: "Join, View, Manage a tournament on Leagues Arena",
    url: `https://${window.location.host}/competitions/${tournamentId}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  let tournamentComplete: boolean | undefined;
  if (fixtures?.[0] === "knockout") {
    const isOngoing =
      reduceFixtures(fixtures?.[1])?.some(
        (fixture) => fixture.status === "ongoing"
      ) || !tournamentData?.generatedFixtures;
    tournamentComplete = !tournamentData?.ongoing || !isOngoing;
  } else if (fixtures?.[0] === "season") {
    const isOngoing =
      fixtures?.[1]?.some((fixture) => fixture.status === "ongoing") ||
      !tournamentData?.generatedFixtures;
    tournamentComplete = !tournamentData?.ongoing || !isOngoing;
  }

  if (isError) {
    return <Navigate to="/not-found" />;
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {tournamentLoading || fixturesLoading ? (
        <div className="flex h-[100vh] w-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center pb-10">
          {!tournamentData?.hasPaid && isTournamentOwner && (
            <div className="sticky left-0 right-0 top-0 z-20 flex w-full items-center justify-between rounded bg-green-light px-4 py-4 text-green-dark">
              <p className="flex items-center gap-2 text-center">
                Your have a pending payment to process
              </p>
              <Link to={`/payment?tournamentId=${tournamentId}`}>Complete</Link>
            </div>
          )}
          <div className="w-full pb-16 lg:max-w-[87%] lg:pb-0">
            <div className="mb-5 mt-16 hidden items-center justify-between lg:flex">
              <h3 className="text-2xl font-medium">Tournament</h3>
              <button
                className="flex items-center gap-2 text-2xl font-medium text-primary-black-darker"
                onClick={() => navigate(-1)}
                aria-label="back"
              >
                <Icons.LaArrowLeft />
                Back
              </button>
            </div>
            <div className="hidden lg:block">
              {isTournamentOwner && (
                <TournamentNavigation
                  tournament={tournamentData}
                  tournamentComplete={tournamentComplete}
                />
              )}
            </div>
            {/* {outstandingPayment && (
              <div className="flex w-full items-center justify-between rounded bg-green-light px-4 py-4 text-green-dark">
                <p className="flex items-center gap-2">
                  <span className="font-bold">Outstanding payment:</span>
                  <span>{formatToNaira(outstandingPayment)}</span>
                </p>
                <button
                  className="flex items-center gap-2 font-bold"
                  disabled={true}
                  title="This feature is not yet available"
                >
                  Pay now{" "}
                  <Icons.LaArrowRight stroke="#3C8505" strokeWidth={2} />{" "}
                </button>
              </div>
            )} */}
            <div
              className={`h-[170px] w-full  bg-cover bg-no-repeat lg:h-[200px]`}
              style={{
                backgroundImage: `url(${tournamentData?.banner})`,
              }}
            >
              <div className="flex items-center justify-between px-4 py-6">
                <button
                  className="rounded-full bg-black p-3 text-3xl lg:hidden"
                  onClick={() => navigate(-1)}
                  aria-label="back"
                >
                  <Icons.LaArrowLeft stroke="#fff" />
                </button>
                <button
                  className="flex items-center gap-2 rounded-full bg-black p-3 text-3xl text-white lg:hidden"
                  onClick={() =>
                    copyToClipboard(
                      `${window.location.host}${window.location.pathname}`,
                      handleCopy,
                      dispatch
                    )
                  }
                >
                  <span className="text-base">Share</span>{" "}
                  <Icons.LaUpload stroke="#fff" />
                </button>
              </div>
            </div>
            <TournamentInfo
              tournament={tournamentData}
              tournamentCompleted={tournamentComplete}
            />
            <div className="lg:hidden">
              {isTournamentOwner && (
                <TournamentNavigation
                  tournament={tournamentData}
                  tournamentComplete={tournamentComplete}
                />
              )}
            </div>
            {fixtures && (
              <TournamentTab
                tournament={tournamentData}
                fixtures={fixtures}
                tournamentLoading={tournamentLoading}
                isTournamentOwner={isTournamentOwner}
              />
            )}
          </div>
        </div>
      )}

      {showTeamConfirmation && isInTournament && userRole === "team" && (
        <ConfirmPopup
          heading="Warning"
          type="warning"
          closeIcon
          description={`You have registered ${noOfPlayersInTeam ?? 0} out of ${
            tournamentData?.noOfPlayers
          } players! Player registration is important for documentation purposes.`}
          buttons={[
            {
              class: "primary",
              handleClick: () => navigate("/discover/players"),
              text: "Register Players",
              rIcon: <Icons.LaArrowRight stroke="#fff" />,
              role: "team",
            },
            {
              class: "secondary",
              handleClick: () => setShowTeamConfirmation(false),
              text: "Cancel",
              role: "team",
            },
          ]}
        />
      )}
    </>
  );
}

export default Tournament;
