import logo from "../../assets/images/logo.svg";

function SpotlightLoader() {
  return (
    <div className="flex h-auto flex-col items-center justify-center gap-1 rounded shadow-md shadow-[#8C8C8C40] lg:gap-4">
      <div className="flex  items-center justify-center">
        <div className="spotlight__preloader flex h-[70px] w-[70px] items-center justify-center  lg:h-[150px] lg:w-[150px]"></div>
        <img
          src={logo}
          alt="leagues arena"
          className="absolute h-[50px] w-[50px] object-contain lg:h-[100px] lg:w-[100px]"
        />
      </div>
      <span className="text-primary-blue-darker xs:text-xs lg:text-base">
        Uploading...
      </span>
    </div>
  );
}

export default SpotlightLoader;
