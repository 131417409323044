import { useGetSpotLightQuery } from "../../redux/services/spotLightService";
import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { MediaCardSkeleton } from "../ui/cards/MediaCards";
import SpotLightFeedsCard from "../spotlight/SpotLightFeedsCard";

interface MyComponentProps {
  index: string | null;
}

export default function SpotlightFeed({ index }: MyComponentProps) {
  console.log(index);
  const [page, setPage] = useState(1);
  const [canIncrementPage, setCanIncrementPage] = useState(false);
  const [spotlights, setSpotlights] = useState<SpotLight[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const { data, isLoading, isFetching } = useGetSpotLightQuery({
    page: page,
    limit: 10,
  });
  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (isFetching || isLoading) return;
    if (inView && hasMore && canIncrementPage) {
      setPage((prevPage) => prevPage + 1);
      setCanIncrementPage(false);
    }
  }, [inView, isFetching, hasMore]);

  useEffect(() => {
    if (data?.spotLights && data.spotLights.length > 0) {
      setSpotlights((prevSpotlights) => [
        ...prevSpotlights,
        ...data.spotLights,
      ]);
      setCanIncrementPage(true);
    }
    if (data?.spotLights.length === 0) {
      // setH(false);
      setPage(1);
    }
  }, [data]);

  const spotlightRefs = React.useRef<(HTMLDivElement | null)[]>([]);
  useEffect(() => {
    if (index != null && spotlightRefs.current[parseInt(index)]) {
      //console.log(spotlightRefs.current[parseInt(index)]);
      spotlightRefs.current[parseInt(index)]?.scrollIntoView({
        behavior: "smooth",
        inline: "end",
      });
    }
  }, [index]);

  return (
    <section className="flex snap-y snap-mandatory flex-col gap-1 overflow-y-scroll lg:mt-10 lg:gap-4 lg:px-8">
      {isLoading &&
        Array.from(
          Array(8).map((_, idx) => (
            <div key={idx} className="w-full lg:w-[50%]">
              <MediaCardSkeleton />
            </div>
          ))
        )}
      {/* Spotlights */}
      {spotlights?.map((spotlight: SpotLight, idx) => (
        <div
          key={`${spotlight._id}_${idx}`}
          ref={(el) => (spotlightRefs.current[idx] = el)}
          className="h-[calc(100dvh-80px)] w-full snap-start bg-black lg:w-[50%] lg:rounded-3xl"
        >
          <SpotLightFeedsCard data={spotlight} />
        </div>
      ))}

      {!isLoading && !isFetching && hasMore && (
        <>
          <div className="w-full lg:w-[50%]">
            <MediaCardSkeleton />
          </div>
          <div
            ref={ref}
            className="my-8 flex items-center justify-center text-center text-lg font-medium"
          />
        </>
      )}
    </section>
  );
}
