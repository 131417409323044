/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, type ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetTournamentQuery,
  useRegisterForTournamentMutation,
  useUpdateTournamentMutation,
} from "../../redux/services/tournamentService";
import {
  LaCardOffline,
  LaCardOnline,
  LaFirstPlace,
  LaSecPlace,
  LaTag,
  LaThrdPlace,
} from "../../components/svg-icons";
import formatToNaira from "../../utils/formatPrice";
import { capitalize } from "../../utils/capitalize";
import { genConfig } from "../../utils/paystack";
import { usePaystackPayment } from "react-paystack";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { ConfirmPopup } from "../../components/modals";
import {
  useGetMyTeamQuery,
  useGetTeamByNameQuery,
} from "../../redux/services/teamService";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { cn } from "../../utils/cn";
import { zodResolver } from "@hookform/resolvers/zod";
import HelmetComponent from "../../components/HelmetComponent";
import { CheckIcon } from "@radix-ui/react-icons";
import { PaymentSummaryAndCheckOut } from "../../components/tournament";

export interface CompetitionCheckoutProps {}

const schema = z.object({
  email: z.string().email(),
});

type CheckoutForm = z.infer<typeof schema>;

export default function CompetitionCheckout(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [registerForTournament, { isLoading }] =
    useRegisterForTournamentMutation();
  const teamName = searchParams.get("team") || "";
  const code = searchParams.get("code") || "";
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CheckoutForm>({
    resolver: zodResolver(schema),
  });
  const watchEmail = watch("email");
  const { data: team, isLoading: isLoadingTeam } = useGetTeamByNameQuery(
    {
      name: teamName.replace("-", " "),
    },
    {
      skip: !teamName,
    }
  );
  const { data: myTeam, isLoading: myTeamLoading } = useGetMyTeamQuery();
  const [updateTournament, { isLoading: isUpdating }] =
    useUpdateTournamentMutation();

  // give space
  const role = (searchParams.get("role") as TRole) || "team";
  const tournamentId = searchParams.get("tournamentId") ?? undefined;
  const [dispayModal, setDisplayModal] = useState(false);
  // space
  const { data: competition } = useGetTournamentQuery({
    id: tournamentId,
  });

  const winningPrizes = [
    {
      prize: competition?.winnerPrize || "0",
      icon: <LaFirstPlace />,
    },
    {
      prize: competition?.runnerUpPrize || "0",
      icon: <LaSecPlace />,
    },
    {
      prize: competition?.thirdPlacePrize || "0",
      icon: <LaThrdPlace />,
    },
  ];
  // const FREE_FEE_PER_PLAYER = 200;

  const subtotal = +(competition?.registrationFee ?? 0);
  const vat = subtotal * 0.075;
  const total = Math.round(subtotal + vat);

  const config = genConfig({
    email: watchEmail,
    amount: total,
  });

  const [paymentType, setPaymentType] = useState<"online" | "offline">(
    "online"
  );

  const initializePayment = usePaystackPayment(config);
  const teamsInCompetition = competition?.teams.filter(
    (team) => team.registered
  );
  const isFull = (teamsInCompetition?.length ?? 0) === competition?.maxTeam;

  async function handlePayment() {
    if (isFull) {
      return dispatch(setToastErrorText("Competition is full"));
    }

    if (team === undefined && myTeam === undefined) {
      dispatch(setToastErrorText("Team does not exist"));
      return navigate(`/discover/players`);
    }

    if (paymentType === "online") {
      // @ts-expect-error - ignore this type error
      initializePayment(onSuccess, onClose);
      return;
    }

    onSuccess("", "offline");
  }

  const onSuccess = (
    response: any,
    paymentMethod: "online" | "offline" = "online"
  ) => {
    if (role === "organizer") {
      const formData = new FormData();
      formData.append("hasPaid", "true");
      formData.append("reference", response.reference);
      formData.append("paymentMethod", paymentMethod);
      updateTournament({
        tournamentId: tournamentId as string,
        body: formData,
      })
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate(`/competitions/${competition?._id}`);
          }, 1000);
          dispatch(setToastSuccess(true));
        })
        .catch((err) => {
          dispatch(setToastError(true));
          console.log(err);
        });
    }
    if (role === "team") {
      registerForTournament({
        teamId: team ? team?._id : myTeam?._id,
        tournamentId: tournamentId as string,
        reference: response.reference,
        paymentMethod,
      })
        .unwrap()
        .then(() => {
          setDisplayModal(true);
          dispatch(setToastSuccess(true));
        })
        .catch((err) => {
          dispatch(setToastError(true));
          console.log(err);
        });
    }
  };
  const onClose = () => {
    console.log("closed");
  };

  // open graph data
  const ogData = {
    title: "Leagues Arena - payment",
    description: "Checkout for a competition on Leagues Arena",
    url: `https://${window.location.host}/payment`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex h-screen flex-col">
        <div className="flex items-center bg-primary-blue-darker p-4 text-white  lg:px-12 lg:py-7">
          <button className="self-start font-bold" onClick={() => navigate(-1)}>
            Cancel
          </button>
          <h1 className="w-full text-center uppercase">Checkout</h1>
        </div>
        <div className="flex h-full flex-col lg:flex-row">
          <div className="flex justify-center px-4 lg:w-[70%] lg:px-0">
            <div className="mt-6 flex max-w-2xl flex-col gap-5 lg:mt-20 lg:gap-12">
              <h2 className="text-2xl lg:text-4xl">
                Confirm details before making payment
              </h2>
              <div className="flex justify-between rounded-lg border border-grey-light px-3 py-6">
                <div>
                  <p className="font-bold">{capitalize(competition?.name)}</p>
                  <p className="text-sm">{competition?.location}, Nigeria</p>
                </div>
                <p className="flex items-center gap-1 bg-green-light px-1  text-green-dark">
                  <LaTag />
                  {formatToNaira(subtotal ?? "0")}
                </p>
              </div>
              {competition?.winnerPrize && (
                <div className="flex flex-col gap-4 lg:gap-6">
                  <h2 className="text-xl lg:text-3xl">Reward</h2>
                  <div className="grid grid-cols-3 justify-between rounded bg-grey-lighter p-3 lg:p-6">
                    {winningPrizes.map((prize) => (
                      <div className="flex flex-col items-center gap-2">
                        {prize.icon}
                        <p className="text-center lg:text-2xl">
                          {formatToNaira(prize.prize)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="mb-4 flex flex-col gap-4 rounded bg-grey-lighter px-3 py-6 lg:gap-6">
                <h2 className="text-xl lg:text-3xl">Select Payment Method</h2>
                <div className="space-y-3">
                  <button
                    className="flex w-full items-center gap-2 rounded-[10px] bg-white px-[14px] py-[10px]"
                    onClick={() => {
                      setPaymentType("online");
                    }}
                  >
                    <div className="rounded-full bg-primary-blue-lighter p-2">
                      <LaCardOnline />
                    </div>
                    <span>Online payment</span>
                    <div
                      className={`ml-auto flex h-5 w-5 items-center justify-center rounded-full ${
                        paymentType === "online"
                          ? "bg-primary-blue-dark"
                          : "bg-grey-light"
                      } `}
                    >
                      <CheckIcon className=" text-white" />
                    </div>
                  </button>
                  <button
                    className="flex w-full items-center gap-2 rounded-[10px]  bg-white px-[14px] py-[10px]"
                    onClick={() => {
                      setPaymentType("offline");
                    }}
                  >
                    <div className="rounded-full bg-primary-blue-lighter p-2">
                      <LaCardOffline />
                    </div>
                    <span>Offline payment</span>
                    <div
                      className={`ml-auto flex h-5 w-5 items-center justify-center rounded-full ${
                        paymentType === "offline"
                          ? "bg-primary-blue-dark"
                          : "bg-grey-light"
                      } `}
                    >
                      <CheckIcon className=" text-white" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-4 mt-2 rounded bg-grey-lighter p-4 pb-10 lg:mt-0 lg:w-[30%] lg:px-8 lg:pt-10">
            {paymentType === "online" ? (
              <form
                action=""
                className="my-2"
                onSubmit={handleSubmit(handlePayment)}
              >
                <p className="mb-4 text-xl font-bold">Proceed to payment</p>
                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="email">
                    Email<span className="text-bold text-red"> *</span>
                  </label>

                  <input
                    type="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    })}
                    id="email"
                    className={cn(
                      "w-full rounded border border-grey-light px-4 py-3",
                      {
                        "border-red": errors.email,
                      }
                    )}
                  />
                  {errors.email && (
                    <p className="text-sm text-red">Email is required</p>
                  )}
                </fieldset>
                <PaymentSummaryAndCheckOut
                  disabled={
                    isLoading || isUpdating || myTeamLoading || isLoadingTeam
                  }
                  isLoading={isLoading || isUpdating}
                  subtotal={subtotal}
                  total={total}
                  vat={vat}
                  competitionNane={competition?.name}
                />
              </form>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePayment();
                }}
              >
                <PaymentSummaryAndCheckOut
                  disabled={
                    isLoading || isUpdating || myTeamLoading || isLoadingTeam
                  }
                  isLoading={isLoading || isUpdating}
                  subtotal={subtotal}
                  total={total}
                  vat={vat}
                  competitionNane={competition?.name}
                />
              </form>
            )}
          </div>
        </div>
      </div>
      {dispayModal && (
        <ConfirmPopup
          heading="Payment is pending verification"
          description="You have registered for the competition. Add players or proceed to competition"
          type="warning"
          buttons={[
            {
              class: "primary",
              text: "Add players",
              role: role,
              handleClick() {
                code
                  ? navigate(`/account/final-profile/${code}`)
                  : navigate(`/discover/players`);
                setDisplayModal(false);
              },
            },

            {
              class: "secondary",
              role: role,
              handleClick() {
                code
                  ? navigate(`/account/final-profile/${code}`)
                  : navigate(`/competitions/${tournamentId}`);
                setDisplayModal(false);
              },
              text: "Proceed to competition",
            },
          ]}
        />
      )}
    </>
  );
}
