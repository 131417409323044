import { formatDistanceToNow, set } from "date-fns";
import { useEffect, useState } from "react";
import { IoPlay } from "react-icons/io5";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { PiShareFatFill } from "react-icons/pi";
import { AiFillHeart } from "react-icons/ai";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useReactToSpotlightMutation } from "../../redux/services/spotLightService";
import { Link } from "react-router-dom";

function SpotLightFeedsCard({ data }: { data: SpotLight }) {
  const role = data.profile;
  let viewerId: string | undefined = undefined;
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  if (isLoggedIn)
    viewerId = useAppSelector((state: RootState) => state?.auth?._id);

  const [likeOrUnlikeSpotlight] = useReactToSpotlightMutation();

  const liked = data.likes.includes(viewerId ?? "");
  const [likesCount, setLikesCount] = useState(data.likes.length);
  const [hasLiked, setHasLiked] = useState(liked);

  function handleLike() {
    setHasLiked((prev) => !prev);
    setLikesCount((prev) => (hasLiked ? prev - 1 : prev + 1));
    likeOrUnlikeSpotlight({
      id: data._id,
    }).unwrap();
  }
  const userProfile = data?.author?.profiles.find(
    (profile) => profile?.role === role
  );
  let isTournamentPost = false;
  if (role !== "player" && role !== "team") {
    isTournamentPost = true;
  }

  const renderMedia = (url: string) => {
    if (url?.match(/\.(jpeg|jpg|gif|png|webp|svg)$/) != null) {
      return (
        <img
          src={url}
          alt="Spotlight"
          className="h-full w-full object-scale-down lg:rounded-3xl"
        />
      );
    } else if (url?.match(/\.(mp4|webm|ogg|mov)$/) != null) {
      return <Video videoUrl={url} />;
    } else {
      return <div>Unsupported media type</div>;
    }
  };

  return (
    <div className="relative h-full w-full">
      <div className="absolute top-0 z-10 flex h-24 w-full items-center bg-black/30 px-2 text-white lg:rounded-t-3xl">
        <div className="flex w-full items-center gap-[10px]">
          <Link
            to={
              isTournamentPost
                ? `/competitions/${data.tournament?._id}?tab=posts`
                : `/profiles/${role}/${userProfile?.name}?tab=posts`
            }
          >
            <img
              src={userProfile?.avatar ?? ""}
              className="h-11 w-11 rounded-full  border border-white"
            />
          </Link>
          <h2 className="text-xl capitalize">
            {isTournamentPost ? data.tournament?.name : userProfile?.name}
          </h2>
          <div className="ml-auto text-sm">
            {formatDistanceToNow(new Date(data.createdAt), { addSuffix: true })}
          </div>
        </div>
      </div>
      {renderMedia(data.urls[0])}
      <div className="absolute bottom-0 z-10 flex h-fit w-full flex-col bg-black/30 px-[10px] pb-3 pt-7 text-white lg:rounded-b-3xl lg:px-4 lg:pb-4">
        <p>{data.description}</p>
        <div className="mt-4 flex w-full items-center justify-between">
          <Link
            to={`/spotlight/${data._id}`}
            className="flex  items-center gap-1"
          >
            <BiSolidMessageSquareDetail className="h-6 w-6" />
            {data.comments}
          </Link>
          <span
            className="flex cursor-pointer items-center gap-1"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/${data._id}`
              );
            }}
          >
            <PiShareFatFill className="h-6 w-6" />
            {data.shares}
          </span>
          <button
            className="flex cursor-pointer items-center gap-1"
            onClick={handleLike}
          >
            <AiFillHeart className={`h-6 w-6 ${hasLiked && "text-red"}`} />
            {likesCount}
          </button>
        </div>
      </div>
    </div>
  );
}

function Video({ videoUrl }: { videoUrl?: string }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    let ignore = false;

    if (!ignore && inView) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }

    return () => {
      ignore = true;
      setIsPlaying(false);
    };
  }, [inView]);

  const handleVideoClick = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div
      ref={ref}
      className="relative h-full w-full"
      onClick={handleVideoClick}
    >
      <ReactPlayer
        url={videoUrl ?? ""}
        playing={isPlaying}
        // muted
        loop
        controls={false}
        width={"100%"}
        height={"100%"}
        // playIcon={<PlayIcon />}
        pip={false}
      />
      {!isPlaying && (
        <div className="absolute left-[48%] top-[48%] flex items-center justify-center">
          <IoPlay className="h-16 w-16 text-white" />
        </div>
      )}
    </div>
  );
}

export default SpotLightFeedsCard;
