import { Link, SetURLSearchParams } from "react-router-dom";
import Slider from "../components/swiper/Slider";
import { LaArrowLeft, LaArrowRight } from "../components/svg-icons";
import { cn } from "../utils/cn";
import { useCallback, useRef, useState } from "react";
import { SwiperRef } from "swiper/react";
import { Button } from "../components/ui";

interface OnboardingLayoutProps {
  slides: JSX.Element[];
  children: React.ReactNode;
  setOnBoardingParams: SetURLSearchParams;
  roleParams: TRole;
}

export default function OnboardingLayout({
  slides,
  children,
  setOnBoardingParams,
  roleParams,
}: OnboardingLayoutProps) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [mobileSlide, setMobileSlide] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  const handleSlideChange = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePaginationClick = useCallback((index: number) => {
    if (swiperRef.current) {
      swiperRef.current?.swiper.slideTo(index);
      setCurrentSlide(index);
    }
    setMobileSlide(index);
  }, []);

  return (
    <div className="relative flex ">
      <div
        className={cn("relative max-h-screen min-h-screen w-full lg:w-[52%]", {
          "hidden lg:block": mobileSlide >= slides.length,
        })}
      >
        <Slider
          slides={slides}
          swiperRef={swiperRef}
          handleSlideChange={handleSlideChange}
        />
        <button
          className="absolute left-4 top-4 z-50 flex h-12 w-12 items-center justify-center rounded-full bg-white text-2xl lg:left-12 lg:top-10"
          onClick={() => {
            setOnBoardingParams({});
          }}
        >
          <LaArrowLeft />
        </button>
        <div className="absolute bottom-4 right-1/2 z-50 hidden items-center gap-2 lg:bottom-6 lg:flex">
          {slides?.map((_, index) => (
            <div
              key={index}
              className={cn(
                "h-4 w-4 cursor-pointer rounded-full bg-white lg:h-5 lg:w-5",
                {
                  "bg-primary-blue-darker": index === currentSlide,
                }
              )}
              onClick={() => handlePaginationClick(index)}
            />
          ))}
        </div>
        <div className="absolute bottom-4 right-4 z-[80] flex w-full justify-end lg:hidden">
          <Button
            role={roleParams}
            intent={"primary"}
            className="rounded-full py-2 text-sm"
            onClick={() => handlePaginationClick(currentSlide + 1)}
          >
            Next
            <LaArrowRight stroke="#fff" />
          </Button>
        </div>
      </div>
      <div
        className={cn(
          "hidden max-h-screen min-h-screen w-full overflow-y-scroll py-20 lg:block lg:w-[74%] lg:py-24",
          {
            block: mobileSlide >= slides.length,
          }
        )}
      >
        <div className="absolute right-0 top-0 flex w-full justify-end bg-white pb-4 pr-4 pt-4 lg:pr-20 lg:pt-10">
          <Link to={"/"}>
            <img
              src={"/logo.svg"}
              alt="leagues arena"
              className="relative z-40 w-[94px] object-contain md:w-[99px]"
            />
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
}
