import {
  ScrollRestoration,
  // Navigate,
  Outlet,
  // useLoaderData,
} from "react-router-dom";
import React from "react";
import { RootState } from "../redux/app/store";
import { useAppSelector, useAppDispatch } from "../redux/app/hooks";
import socket from "../socket";
import jwt_decode from "jwt-decode";
import { FloatingButton } from "../components/ui";
import { BottomNav, NavMobile, SideNav } from "../components/navigations";
import RingPlayer from "../components/RingPlayer";
import { setPing, setSignals } from "../redux/features/socket/socketSlice";
import {
  ToastCopied,
  ToastError,
  ToastErrorText,
  ToastSuccess,
} from "../components/ui/toast";
import { CreateCompetitionModal, PromptRating } from "../components/modals";
import { setModalToDisplay } from "../redux/features/modal/displayModalSlice";
import { useGetPlayersToRateQuery } from "../redux/services/ratingService";
import PreviewSpotLightModal from "../components/modals/PreviewSpotLightModal";
import SpotLightCompetitionListModal from "../components/modals/SpotlightCompetitionListModal";
import { leaguesArenaApi } from "../redux/services/leaguesArenaApiSlice";
import { setSpotlightLoading } from "../redux/features/spotlight/spotlightSlice";
import UpdateTeamStatusModal from "../components/modals/UpdateTeamStatusModal";

const token = localStorage.getItem("token");

interface SpotlightData {
  status?: string;
  spotLightId: string;
  profile: string;
}
function isSpotlightData(data: unknown): data is SpotlightData {
  return typeof data === "object" && data !== null && "status" in data;
}
function BaseLayout() {
  const claimedId = token && (jwt_decode(token) as any)._id;
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector((state: RootState) => state.auth);
  const toastSuccess = useAppSelector(
    (state: RootState) => state.displayModal.toastSuccess
  );
  const toastError = useAppSelector(
    (state: RootState) => state.displayModal.toastError
  );
  const toastCopied = useAppSelector(
    (state: RootState) => state.displayModal.toastCopied
  );
  const toastErrorText = useAppSelector(
    (state: RootState) => state.displayModal.toastErrorText
  );
  const modalToDisplay = useAppSelector(
    (state: RootState) => state.displayModal.modalToDisplay
  );

  function wsSingleProcessor(data: unknown) {
    if (isSpotlightData(data)) {
      if (data.status === "spotlight-uploading") {
        dispatch(
          setSpotlightLoading({
            id: data.spotLightId,
            loading: true,
            profile: data.profile,
          })
        );
        return;
      }

      if (data.status === "spotlight-uploaded") {
        dispatch(
          setSpotlightLoading({
            id: data.spotLightId,
            loading: false,
            profile: data.profile,
          })
        );
        dispatch(leaguesArenaApi.util.invalidateTags(["Spotlight"]));
        return;
      }
    }
    dispatch(setSignals({ emitted: true, received: true, count: 1 }));
    dispatch(setPing(true));
  }

  React.useEffect(() => {
    socket.on(`${claimedId}`, wsSingleProcessor);

    return () => {
      socket.off(`${claimedId}`, wsSingleProcessor);
    };
  }, [socket]);

  if (isLoggedIn && !socket.connected) {
    socket.auth = { claimedId };
    socket.connect();
  }

  const tournamentId = localStorage.getItem("tournamentId");

  return (
    <>
      <NavMobile />
      <section className="flex">
        <SideNav />
        <section className="w-full flex-1 lg:absolute lg:right-0 lg:w-[82%]">
          <Outlet />

          {modalToDisplay === "create-competition" && (
            <CreateCompetitionModal />
          )}
          {modalToDisplay === "competition-spotlight" && (
            <SpotLightCompetitionListModal />
          )}

          {modalToDisplay === "spotLight" && (
            <PreviewSpotLightModal tournamentId={tournamentId ?? undefined} />
          )}

          {modalToDisplay === "updateTeamStatus" && <UpdateTeamStatusModal />}
        </section>
      </section>
      <BottomNav />
      {toastSuccess && <ToastSuccess />}
      {toastError && <ToastError />}
      {toastCopied && <ToastCopied />}
      {toastErrorText && <ToastErrorText />}
      <ScrollRestoration />
      <RingPlayer />
    </>
  );
}

export default BaseLayout;
