import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routesConfig } from "./routes";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://bd04dfe8ec9a8817496b9cc03b5bbb29@o4506571572707328.ingest.us.sentry.io/4507225858113536",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://leaguesarena.com",
    // /^https:\/\/yourserver\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const routes = createBrowserRouter(routesConfig);

function App() {
  return (
    <div className="relative ">
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
