import type { ReactElement } from "react";
import {
  LaArrowLeft,
  LaClose,
  LaDelete,
  LaEditPencil,
  LaPlusIcon,
} from "../../components/svg-icons";
import { cn } from "../../utils/cn";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/ui";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { InviteForm } from "../../components/discover";
import { CreateTeamsForCompetition } from "../../components/tournament";
import { useUpdateMeMutation } from "../../redux/services/userService";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { RootState } from "../../redux/app/store";
import { ConfirmPopup } from "../../components/modals";
import RegisteredPhoneInput from "../../components/ui/input/RegisteredPhoneInput";
import HelmetComponent from "../../components/HelmetComponent";

export interface CreateTeamsFormForCompetitionProps {}

const schema = z.object({
  name: z.string().min(3, "name is too short").max(50, "name is too long"),
  phoneNumber: z
    .string()
    .regex(/^[0-9]+$/, "Must be only digits")
    .max(11, "phone number is too long")
    .min(11, "phone number is too short"),
  phonecode: z
    .string()
    .min(2, "phone code is too short")
    .max(5, "phone code is too long"),
});

export type CreateTeamsForCompetitionForm = z.infer<typeof schema>;

export default function CreateTeamsFormForCompetition(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateUser] = useUpdateMeMutation();
  const [dispayModal, setDisplayModal] = React.useState<boolean>(false);
  const role = useAppSelector((state: RootState) => state.auth.role);
  const profile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "organizer")?.profile;

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateTeamsForCompetitionForm>({
    resolver: zodResolver(schema),
  });
  const watchName = watch("name");
  const watchPhoneNumber = watch("phoneNumber");
  const watchPhonecode = watch("phonecode");
  const [selectedCompetition, setSelectedCompetition] =
    React.useState<string>("none");
  const [maxTeam, setMaxTeam] = React.useState<number>(0);
  const [teamsInfo, setTeamsInfo] = React.useState<
    CreateTeamsForCompetitionForm[]
  >([]);
  const [competitionTeams, setCompetitionTeams] = React.useState<
    Tournament["teams"]
  >([]);

  const onSubmit = (data: CreateTeamsForCompetitionForm) => {
    selectedCompetition === "none" && setSelectedCompetition("");
    setTeamsInfo((prev) => [...prev, data]);
  };

  const handleDelete = (name: string) => {
    setTeamsInfo((prev) => prev.filter((team) => team.name !== name));
  };

  const handleEdit = (name: string) => {
    setTeamsInfo((prev) => prev.filter((team) => team.name !== name));
    reset({
      name: teamsInfo.find((team) => team.name === name)?.name ?? "",
      phoneNumber:
        teamsInfo.find((team) => team.name === name)?.phoneNumber ?? "",
      phonecode: teamsInfo.find((team) => team.name === name)?.phonecode ?? "",
    });
  };

  function handleSwitchProfile() {
    if (!profile) return navigate("/account/onboarding?role=organizer&step=2");
    updateUser({ role: "organizer" })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setDisplayModal(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  React.useEffect(() => {
    if (role !== "organizer") {
      setDisplayModal(true);
    }
  }, [role, profile]);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Teams",
    description: "Create teams for your competition on Leagues Arena",
    url: `https://${window.location.host}/discover/create-teams`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center justify-center pb-10">
        <div className="w-full lg:max-w-[87%]">
          <div
            className={cn("pb-24 pt-4 lg:pb-0", {
              "pb-40 lg:pb-40": teamsInfo.length,
            })}
          >
            <div className="px-4">
              <div className="flex items-center gap-4 py-4 lg:px-0">
                <button
                  aria-label="back"
                  className="text-xl lg:hidden"
                  onClick={() => navigate(-1)}
                >
                  <LaArrowLeft />
                </button>
                <h3 className="text-xl font-bold">Add team manually</h3>
              </div>
              <p>
                Create a new team by providing the details below. The contact
                person will be notified to complete team set up
              </p>
            </div>
            <div className="flex flex-col items-center justify-center px-4 lg:px-0">
              <form
                action=""
                className="mt-6 flex flex-col gap-2  md:w-[71%] lg:w-[60%]"
                onSubmit={handleSubmit(onSubmit)}
              >
                <fieldset>
                  <label htmlFor="name" className="text-sm md:text-base">
                    Team Name<span className="text-red">*</span>
                  </label>
                  <Input
                    role="organizer"
                    type="text"
                    id="name"
                    register={register}
                    name={"name"}
                    placeholder="Enter team name"
                    required
                    className={cn(
                      "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-opacity-30 lg:text-xl",
                      {
                        "error border-red focus:border-red": errors.name,
                      }
                    )}
                  />
                  {errors.name && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.name.message}
                    </span>
                  )}
                </fieldset>

                <fieldset>
                  <label htmlFor="phone" className="text-sm md:text-base">
                    Contact person’s number <span className="text-red">*</span>
                  </label>

                  <RegisteredPhoneInput
                    name="phoneNumber"
                    error={errors.phoneNumber?.message}
                    phonecodeName={"phonecode"}
                    register={register}
                    required="Phone number is required"
                    id="phone-number"
                    setValue={setValue}
                  />

                  {errors.phoneNumber && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </fieldset>
                <fieldset className="mt-2 flex items-center justify-between">
                  <button
                    type="button"
                    className="flex items-center gap-2 text-red"
                    onClick={() => navigate(-1)}
                  >
                    <span className="flex h-4 w-4 items-center justify-center rounded-full border border-red">
                      <LaClose className="text-[8px]" stroke="#f00" />
                    </span>
                    Cancel
                  </button>
                  <button
                    className="flex items-center gap-2 text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={teamsInfo.some(
                      (team) =>
                        team.name === watchName ||
                        `${team.phonecode}${team.phoneNumber}` ===
                          `${watchPhonecode}${watchPhoneNumber}`
                    )}
                  >
                    <LaPlusIcon />
                    Add team
                    {teamsInfo.length > 0 && " another team"}
                  </button>
                </fieldset>
              </form>
              <div className="mt-6 flex max-h-[400px] w-full  flex-col gap-2 overflow-y-scroll md:w-[71%] lg:w-[60%]">
                {teamsInfo.map((team) => {
                  return (
                    <div
                      key={team.name}
                      className="flex items-center justify-between rounded bg-primary-black-lighter p-4"
                    >
                      <div className="text-sm">
                        <p className="font-bold">{team.name}</p>
                        <p>
                          {team.phonecode} {team.phoneNumber}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleEdit(team.name)}
                          type="button"
                        >
                          <LaEditPencil />
                        </button>
                        <button
                          onClick={() => handleDelete(team.name)}
                          type="button"
                        >
                          <LaDelete />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!selectedCompetition && (
        <InviteForm
          setSelectedCompetition={setSelectedCompetition}
          setMaxTeam={setMaxTeam}
          setCompetitionTeams={setCompetitionTeams}
        />
      )}

      {teamsInfo.length > 0 && selectedCompetition.length > 4 && (
        <CreateTeamsForCompetition
          teamsInfo={teamsInfo}
          setTeamsInfo={setTeamsInfo}
          maxTeam={maxTeam}
          selectedCompetition={selectedCompetition}
          setSelectedCompetition={setSelectedCompetition}
          noOfJoinedTeams={competitionTeams.length}
        />
      )}
      {dispayModal && (
        <ConfirmPopup
          heading={
            profile ? "Switch your profile" : "Create an organizer profile"
          }
          type="warning"
          description={`You need to ${
            profile ? "switch your profile" : "create an organizer profile"
          } to perform this action`}
          buttons={[
            {
              text: "Proceed",
              handleClick: handleSwitchProfile,
              class: "primary",
            },
            {
              text: "Cancel",
              handleClick: () => {
                setDisplayModal(false);
                navigate(-1);
              },
              class: "secondary",
            },
          ]}
        />
      )}
    </>
  );
}
