import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import {
  setDisplayModal,
  setModalToDisplay,
} from "../../redux/features/modal/displayModalSlice";
import { LaCaretLeft, LaCaretRight, LaClose } from "../svg-icons";
import { Textarea } from "../ui";
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useCreateSpotLightMutation } from "../../redux/services/spotLightService";
import LaSpinner from "../svg-icons/LaSpinner";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { IoPause, IoPlay } from "react-icons/io5";
import { RootState } from "../../redux/app/store";
import useOnClickOutside from "../../hooks/useClickedOutside";
import socket from "../../socket";
import { nanoid } from "nanoid";
import { profile } from "console";
import { useNavigate } from "react-router-dom";

function PreviewSpotLightModal({ tournamentId }: { tournamentId?: string }) {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const userId = useAppSelector((state: RootState) => state.auth.myId);
  const [files, setFiles] = useState<File[]>([]);
  const [filesUrls, setFilesUrls] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [caption, setCaption] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const elRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [uploadSpotLight, { isLoading }] = useCreateSpotLightMutation();

  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();

  useOnClickOutside(modalRef, () => {
    dispatch(setModalToDisplay(""));
  });

  const handleVideoClick = () => {
    setIsPlaying((prev) => !prev);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (elRef.current) {
      // Prevent further automatic clicks after the first one

      elRef.current.click();
    }
    return () => {
      document.body.style.overflow = "auto";
      // Revoke all file URLs to avoid memory leaks
      filesUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []);

  // Update selectedFile when files or currentFileIndex changes
  useEffect(() => {
    if (filesUrls.length > 0 && filesUrls[currentFileIndex]) {
      setSelectedFile(filesUrls[currentFileIndex]);
      // Ensure the ref exists and scroll it into view
      imageRefs.current[currentFileIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  }, [files, currentFileIndex]);

  // Ensure imageRefs array can hold all file refs
  useEffect(() => {
    imageRefs.current = imageRefs.current.slice(0, files.length);
  }, [files]);

  // const isInitialMount = useRef(true);
  // const location = useLocation();
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     // This effect runs only when the location changes, not on initial mount.
  //     dispatch(setModalToDisplay(""));
  //   }
  // }, [location, dispatch]);

  // adds the files to the state
  function handleFileInput(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    if (target.files) {
      // Convert FileList to Array
      const filesArray = Array.from(target.files);
      const newFileUrls = filesArray.map((file) => URL.createObjectURL(file));
      setFiles((prev) => [...filesArray, ...prev]);
      setFilesUrls((prev) => [...newFileUrls, ...prev]);
      setSelectedFile(filesUrls[0]);
    }

    if (modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "instant", block: "end" });
    }
  }
  const handleDeleteFile = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    const newFilesUrls = filesUrls.filter((_, i) => i !== index);
    setFiles(newFiles);
    setFilesUrls(newFilesUrls);

    // Adjust currentFileIndex based on deletion
    if (newFiles.length > 0) {
      if (index === files.length - 1) {
        // If the deleted file was the last, select the new last
        setCurrentFileIndex(newFiles.length - 1);
      } else if (index === currentFileIndex) {
        // If the current file is deleted, but it's not the last, keep the index the same but update the preview
        setCurrentFileIndex((prevIndex) => prevIndex % newFiles.length);
      }
      // Update the selected file preview
      const newFileURL = newFilesUrls[currentFileIndex % newFiles.length];
      setSelectedFile(newFileURL);
    } else {
      // If no files are left, reset everything
      setSelectedFile(null);
      setCurrentFileIndex(0); // Or another default value
      dispatch(setModalToDisplay(""));
    }
  };

  const handleNext = () => {
    setCurrentFileIndex((prevIndex) => {
      const index = (prevIndex + 1) % files.length;
      setSelectedFile(filesUrls[index]);
      return index;
    });
  };

  const handlePrevious = () => {
    setCurrentFileIndex((prevIndex) => {
      let index = prevIndex - 1;
      if (index < 0) {
        index = files.length - 1;
      }
      setSelectedFile(filesUrls[index]);
      return index;
    });
  };

  function handleSubmit() {
    if (files.length > 5) {
      alert("You can upload a maximum of 5 files.");
      return;
    }
    const form = new FormData();
    form.append("caption", caption);
    form.append("profile", role);
    if (tournamentId) {
      form.append("tournament", tournamentId);
    }
    files.forEach((file) => form.append("media", file));
    uploadSpotLight(form)
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setModalToDisplay(""));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleSendMedia() {
    const CHUNK_SIZE = 1024 * 1024;
    const id = nanoid();
    // send Data
    socket.emit("spotlight-upload", [
      "data",
      {
        id: id,
        caption: caption,
        profile: role,
        ...(role === "organizer" && { tournament: tournamentId }),
        userId: userId,
      },
    ]);
    // console.log('sending file')
    const file = files[0];
    if (files) {
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      const currentChunk = 0;

      const sendChunk = async (chunkNumber: number) => {
        const start = chunkNumber * CHUNK_SIZE;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        const chunk = file.slice(start, end);
        const arrayBuffer = await chunk.arrayBuffer();

        socket.emit("spotlight-upload", [
          "chunk",
          {
            id: id,
            chunk: arrayBuffer,
            chunkNumber: chunkNumber,
            totalChunks,
            type: file.type,
          },
        ]);

        console.log(`chunk ${chunkNumber + 1}/${totalChunks} sent`);

        if (chunkNumber + 1 < totalChunks) {
          sendChunk(chunkNumber + 1);
        } else {
          setIsUploading(false);
          dispatch(setModalToDisplay(""));
          console.log("file sent");
        }
      };

      // Start sending chunks
      sendChunk(currentChunk);
    }
  }

  return (
    <div className="absolute bottom-0 right-0 top-0 z-[100] min-h-screen w-[100vw] justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center px-4 lg:px-0">
        <div className="relative flex h-[613px] w-full flex-col  rounded-xl bg-black p-4 py-8 shadow lg:w-[28%] lg:p-0">
          <section
            className="relative z-10 flex w-full flex-grow flex-col rounded-b-xl rounded-t-xl"
            ref={modalRef}
          >
            {files.length > 0 &&
              files[currentFileIndex] &&
              (files[currentFileIndex].type.startsWith("video/") ? (
                // <video
                //   src={filesUrls[currentFileIndex] ?? ""}
                //   // autoPlay
                //   controls
                //   className="absolute -z-10 h-full w-full rounded-xl bg-black object-scale-down"
                //   // autoPlay
                // >
                //   Your browser does not support the video tag.
                // </video>
                <div onClick={handleVideoClick}>
                  <Video
                    videoUrl={filesUrls[currentFileIndex]}
                    isPlaying={isPlaying}
                  />
                  {isPlaying ? (
                    <div className="absolute left-[45%] top-[40%] flex items-center justify-center">
                      <IoPause className="h-12 w-12 text-white" />
                    </div>
                  ) : (
                    <div className="absolute left-[45%] top-[40%] flex items-center justify-center">
                      <IoPlay className="h-12 w-12 text-white" />
                    </div>
                  )}
                </div>
              ) : (
                <img
                  src={selectedFile ?? ""}
                  alt="Selected file"
                  className="absolute -z-10 h-full w-full rounded-xl bg-black object-scale-down"
                />
              ))}
            <input
              type="file"
              className="hidden"
              ref={elRef}
              accept="image/*,video/*"
              onChange={handleFileInput}
            />
            <div className="absolute top-1 flex w-full items-center justify-between px-5 py-4">
              <button
                className=""
                onClick={() => dispatch(setModalToDisplay(""))}
              >
                <LaClose stroke="white" width={17} height={17} />
              </button>
              {/* <div className=" flex items-center justify-center rounded-[30px] border border-white px-[10px] py-1 text-sm text-white">
                <span>{files.length}</span>
              </div> */}
            </div>
            {/* <div className="absolute top-[40%] flex w-full items-center justify-between px-5 py-4">
              <button className="" onClick={handlePrevious}>
                <LaCaretLeft stroke="white" />
              </button>
              <button className="" onClick={handleNext}>
                <LaCaretRight stroke="white" />
              </button>
            </div> */}
            <div className=" mx-5 mt-auto flex max-w-[500px] justify-start gap-3 overflow-x-auto px-1">
              {/* {files.length <= 5 && (
                <button
                  className="flex h-[70px] w-[70px] min-w-[70px] items-center justify-center rounded-md border-2 border-white bg-transparent"
                  onClick={() => {
                    if (elRef.current) {
                      elRef.current.click();
                    }
                  }}
                >
                  <FaPlus className="h-6 w-6 font-bold text-white" />
                </button>
              )} */}
              {/* peview images */}
              {files.map((file, index) => (
                <div
                  key={index}
                  ref={(el) => (imageRefs.current[index] = el)}
                  className={`relative h-[70px] w-[70px] min-w-[70px] rounded-md border-2 border-white`}
                  onClick={() => {
                    setCurrentFileIndex(index);
                    setSelectedFile(filesUrls[index]);
                  }}
                >
                  {file.type.startsWith("video/") ? (
                    <video
                      src={filesUrls[index]}
                      controls
                      className="absolute -z-10 h-full w-full rounded-md bg-primary-black-darker object-scale-down"
                    >
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={filesUrls[index]}
                      className="absolute -z-10 h-full w-full rounded-md bg-primary-black-darker object-scale-down"
                    />
                  )}

                  {currentFileIndex === index && (
                    <div className="flex h-full w-full items-center justify-center rounded-md bg-black/30">
                      <RiDeleteBin6Line
                        className="h-6 w-6 text-white"
                        onClick={() => handleDeleteFile(index)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="px-4 py-1 ">
              <div className="relative h-fit w-full ">
                <Textarea
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  maxLength={500}
                  name="name"
                  placeholder="Add description"
                  role="player"
                  className="min-h-fit w-full rounded-2xl pb-8 text-[10px] xs:text-sm lg:text-base"
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="rounded-2xl bg-primary-blue-dark px-6 text-sm  text-white disabled:bg-grey-light  xs:h-9 xs:py-2 lg:h-12 lg:py-3"
                  onClick={handleSubmit}
                  disabled={files.length < 1 || isLoading}
                >
                  {isLoading ? (
                    <div className="animate-spin">
                      <LaSpinner />
                    </div>
                  ) : (
                    "Post"
                  )}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

function Video({
  videoUrl,
  isPlaying,
}: {
  videoUrl: string;
  isPlaying: boolean;
}) {
  return (
    <div className="absolute -z-10 h-full w-full rounded-xl bg-black object-scale-down">
      <ReactPlayer
        url={videoUrl}
        playing={isPlaying}
        // muted
        loop
        controls={false}
        width={"100%"}
        height={"100%"}
        // playIcon={<PlayIcon />}
        pip={false}
      />
    </div>
  );
}

export default PreviewSpotLightModal;
