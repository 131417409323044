import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SpotlightLoadingState {
  id: string;
  loading: boolean;
  profile: string;
}

const initialState: SpotlightLoadingState[] = [];

const spotLight = createSlice({
  name: "spotlight",
  initialState: initialState,
  reducers: {
    setSpotlightLoading: (
      state,
      action: PayloadAction<SpotlightLoadingState>
    ) => {
      const { id, loading, profile } = action.payload;
      const existingSpotlight = state.find((spotlight) => spotlight.id === id);
      if (existingSpotlight) {
        existingSpotlight.loading = loading;
      } else {
        state.push({ id, loading, profile });
      }
    },
  },
});

export const { setSpotlightLoading } = spotLight.actions;
export default spotLight.reducer;
