import Input from "../ui/input/Input";
import { cn } from "../../utils/cn";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { CreateTournamentFormValues } from "../../pages/create-tournament/CreateTournament";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../mocks/store";
import formatToNaira from "../../utils/formatPrice";
import { LaCheck, LaInfo } from "../svg-icons";
import React from "react";
import useOnClickOutside from "../../hooks/useClickedOutside";
import LaSpinner from "../svg-icons/LaSpinner";

interface InputProps {
  register: UseFormRegister<CreateTournamentFormValues>;
  errors: FieldErrors<CreateTournamentFormValues>;
  handleSubmit: UseFormHandleSubmit<CreateTournamentFormValues>;
  onSubmit: SubmitHandler<CreateTournamentFormValues>;
  watchRegistrationFee: string;
  watchNoOfPlayers: string;
  watchRegistrationType: string;
  watchMaxTeam: string;
  isLoading: boolean;
  laFreeCut: number;
  totalFee: number;
}

export default function TournamentRegistrationFeeForm({
  register,
  errors,
  handleSubmit,
  onSubmit,
  watchRegistrationType,
  isLoading,
  laFreeCut,
  totalFee,
}: InputProps) {
  const role = useAppSelector((state: RootState) => state.auth.role);

  const [displayTooltip, setDisplayTooltip] = React.useState(false);
  const tooltipEl = React.useRef(null);

  useOnClickOutside(tooltipEl, () => {
    setDisplayTooltip(false);
  });

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            Team registration
          </h2>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2 pb-20"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset className="flex flex-col gap-3">
            <p>Select competition type</p>
            <fieldset className="flex flex-col gap-3">
              {["public", "private"].map((opt, i) => (
                <label
                  htmlFor={`${opt}-${i}`}
                  className="flex cursor-pointer items-center gap-2 capitalize"
                >
                  <input
                    type="radio"
                    id={`${opt}-${i}`}
                    {...register("tournamentType", {
                      required: "Competition type is required",
                    })}
                    value={opt}
                    className="peer"
                    hidden
                  />
                  <div className="flex h-5 w-5 items-center justify-center rounded border border-grey-light peer-checked:bg-primary-black-darker">
                    <LaCheck
                      stroke="#fff"
                      strokeWidth={2}
                      className="text-sm"
                    />
                  </div>

                  {opt === "public" ? "Anyone can join" : opt}
                </label>
              ))}
              {errors?.tournamentType && (
                <p className="text-sm italic text-red">
                  {errors?.tournamentType?.message}
                </p>
              )}
            </fieldset>
          </fieldset>
          <fieldset className="flex flex-col gap-3">
            <p>Select registration type</p>
            <fieldset className="flex flex-col gap-2">
              {["free", "paid"].map((opt, i) => (
                <label
                  htmlFor={`${opt}-${i}`}
                  className="flex cursor-pointer items-center gap-2 capitalize"
                >
                  <input
                    type="radio"
                    id={`${opt}-${i}`}
                    {...register("registrationType", {
                      required: "Registration type is required",
                    })}
                    value={opt}
                    className="peer"
                    hidden
                  />
                  <div className="flex h-5 w-5 items-center justify-center rounded border border-grey-light peer-checked:bg-primary-black-darker">
                    <LaCheck
                      stroke="#fff"
                      strokeWidth={2}
                      className="text-sm"
                    />
                  </div>

                  {opt}
                </label>
              ))}
            </fieldset>
          </fieldset>

          {watchRegistrationType === "paid" && (
            <>
              <fieldset className="flex w-full flex-col items-stretch gap-2">
                <label htmlFor="registration">
                  Registration fee (per team)
                </label>
                <Input
                  role={role}
                  register={register}
                  {...register("registrationFee", {
                    required: "Registration fee is required",
                    min: { value: 1, message: "Minimum ₦1" },
                    maxLength: 30,
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numbers allowed",
                    },
                  })}
                  id="registration"
                  type="text"
                  placeholder="e.g ₦30,000"
                  className={cn(
                    "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                    {
                      "border-red focus:border-4 focus:border-red focus:border-opacity-30":
                        errors?.registrationFee,
                    }
                  )}
                />
                {/* <div className="relative">
                  <p
                    onMouseOver={() => setDisplayTooltip(true)}
                    onMouseOut={() => setDisplayTooltip(false)}
                    onTouchStart={() => setDisplayTooltip(!displayTooltip)}
                    className="flex cursor-pointer items-center gap-2"
                    title="We apply a fixed fee of ₦800 per player per max no. of players in a team. This service fee will be added to the team registration fee set by you."
                    ref={tooltipEl}
                  >
                    Team will pay {formatToNaira(totalFee)} after addition of
                    processing fee
                    <LaInfo className="text-xl" />
                  </p>
                  {displayTooltip && (
                    <p className="absolute bottom-10 right-10 max-w-sm rounded bg-white p-4 shadow lg:p-6">
                      We apply a fixed fee of{" "}
                      <span className="text-[#FF6600]">#800.00</span> per player
                      per max no. of players in a team. This service fee will be
                      added to the team registration fee set by you.
                    </p>
                  )}
                </div> */}

                {errors?.registrationFee && (
                  <p className="text-sm text-red">
                    {errors?.registrationFee?.message}
                  </p>
                )}
              </fieldset>
              <button
                className={cn(
                  "mt-6 flex w-full items-center justify-center gap-2 rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:mt-6 md:text-2xl"
                )}
              >
                Create Competition
                {isLoading && <LaSpinner className="h-6 w-6 animate-spin" />}
              </button>
            </>
          )}

          {watchRegistrationType === "free" && (
            <>
              <p>You will be charged a fee of {formatToNaira(laFreeCut)}</p>
              <button
                className={cn(
                  "mt-4 flex w-full items-center justify-center gap-2 rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:mt-6 md:text-2xl"
                )}
              >
                Proceed to payment
                {isLoading && <LaSpinner className="h-6 w-6 animate-spin" />}
              </button>
            </>
          )}
        </form>
      </div>
    </section>
  );
}
