import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { MediaCardSkeleton } from "../ui/cards/MediaCards";
import { useGetUserSpotlightQuery } from "../../redux/services/spotLightService";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import SpotLightCard from "../spotlight/SpotLightCard";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/app/store";

import SpotlightLoader from "../spotlight/SpotlightLoader";

export default function SpotlightGrid({
  profile,
  isPrivate,
  userId,
}: {
  profile: "player" | "team" | "organizer";
  isPrivate: boolean;
  userId: string;
}) {
  const { data, isLoading } = useGetUserSpotlightQuery({
    userid: userId,
    profile,
  });

  const dispatch = useAppDispatch();
  const spotlightsUploading = useAppSelector(
    (state: RootState) => state.spotLight
  );

  return (
    <div className="w-full">
      {isPrivate && (
        <div className="flex items-center gap-4  px-4 py-4 text-base font-medium lg:px-0">
          <button
            className="rounded-full border border-grey-light px-3 py-1 hover:bg-grey-lighter"
            onClick={() => {
              dispatch(setModalToDisplay("spotLight"));
            }}
          >
            Create Post
          </button>
        </div>
      )}
      <div className="my-4">
        <section className="relative grid w-full grid-cols-3 justify-center gap-2 px-4 md:grid-cols-4 md:gap-4 lg:px-0">
          {isLoading &&
            Array.from(Array(9)).map((_, idx) => (
              <MediaCardSkeleton key={idx} />
            ))}
          {!isLoading && (
            <>
              {spotlightsUploading.map((spt) => {
                if (!spt.loading || spt.profile !== profile) {
                  return null;
                }
                return <SpotlightLoader key={spt.id} />;
              })}

              {data?.userSpotLights?.map((spt: SpotLight, idx: number) => {
                if (spt.status === "loading") {
                  return <SpotlightLoader key={spt._id} />;
                }
                return (
                  <Link
                    to={`/spotlight/${profile}/${userId}?index=${idx}`}
                    key={spt._id}
                  >
                    <SpotLightCard spotlight={spt} />
                  </Link>
                );
              })}
            </>
          )}
        </section>
      </div>
    </div>
  );
}
