import React, { useState } from "react";
import { PiPlayCircleFill } from "react-icons/pi";
import { useSwipeable } from "react-swipeable";

interface SpotLightCardProps {
  spotlight: SpotLight;
}

const SpotLightCard: React.FC<SpotLightCardProps> = ({ spotlight }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("left"),
    onSwipedRight: () => handleSwipe("right"),
  });

  const handleSwipe = (direction: "left" | "right") => {
    if (direction === "left") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % spotlight.urls.length);
    } else {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + spotlight.urls.length) % spotlight.urls.length
      );
    }
  };

  const renderMedia = (url: string) => {
    if (url?.match(/\.(jpeg|jpg|gif|png|webp|svg)$/) != null) {
      return (
        <img
          src={url}
          alt="Spotlight"
          className="h-full w-full object-cover object-center"
        />
      );
    } else if (url?.match(/\.(mp4|webm|ogg|mov)$/) != null) {
      return (
        <>
          <video className="relative  object-cover object-center">
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute top-[40%] z-10 flex w-full justify-center lg:top-[45%]">
            <PiPlayCircleFill className=" h-8 w-8 text-white lg:h-10 lg:w-10" />
          </div>
        </>
      );
    } else {
      return <div>Unsupported media type</div>;
    }
  };

  return (
    <div
      {...handlers}
      className="W-[200px] relative h-[100px] overflow-hidden rounded-lg bg-black md:h-[200px] lg:h-[300px]"
    >
      {/* <div className="absolute left-1 top-1 z-10 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-primary-blue-darker text-xs text-white lg:left-2 lg:top-2 lg:h-8 lg:w-8 lg:text-sm">
        {spotlight.urls.length}
      </div> */}

      {renderMedia(spotlight.urls[currentIndex])}
    </div>
  );
};

export default SpotLightCard;
