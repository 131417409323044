import * as Page from "../pages";
import * as Layouts from "../layouts";
import { action as ScoreAction } from "../pages/scores/EnterScore";
import { Outlet } from "react-router-dom";
import PlayerPostsIndex from "../pages/user-profiles/PlayerPostsIndex";
import PlayerPostsId from "../pages/user-profiles/PlayerPostsId";

const routesConfig = [
  {
    path: "/",
    element: <Layouts.Wrapper />,
    children: [
      // { path: "/home", element: <Page.Landing /> },
      { path: "/", element: <Page.CompetitionLandingIndex /> },
      {
        element: <Layouts.Base />,
        errorElement: <Page.ErrorPage />,
        children: [
          { path: "test", element: <div>banan</div> },
          { path: "home", element: <Page.Home /> },
          { path: "notifications", element: <Page.Updates /> },
          {
            path: "discover",
            element: <Layouts.Auth />,
            children: [
              { path: "", element: <Page.Discover /> },
              { path: "players", element: <Page.Players /> },
              { path: "competitions", element: <Page.Competitions /> },
              { path: "teams", element: <Page.Teams /> },
              {
                path: "add-teams",
                element: <Page.SearchAndAddTeamsPage />,
              },
              {
                path: "create-teams",
                element: <Page.CreateTeamsFormForCompetition />,
              },
              {
                path: "create-players",
                element: <Page.CreatePlayersFormForTeam />,
              },
            ],
          },

          {
            path: "profiles",
            children: [
              {
                path: "",
                element: <Page.NotFound />,
              },
              {
                path: "organizer/",
                children: [
                  { path: ":name", element: <Page.OrganizerProfile /> },
                  { path: "edit", element: <Page.EditOrganizerProfile /> },
                ],
              },
              {
                path: "team/",
                children: [
                  { path: ":teamName", element: <Page.Team /> },
                  { path: "edit", element: <Page.EditTeam /> },
                  {
                    path: "posts",
                    children: [
                      { path: "", element: <PlayerPostsIndex /> },
                      { path: ":id", element: <PlayerPostsId /> },
                    ],
                  },
                ],
              },
              {
                path: "player/",
                children: [
                  { path: ":name", element: <Page.PlayerProfile /> },
                  { path: "edit", element: <Page.EditPlayerProfile /> },
                ],
              },
              {
                path: "fan",
                children: [{ path: ":name", element: <Page.FanProfile /> }],
              },
            ],
          },
          {
            path: "training",
            children: [
              {
                path: "",
                element: <Page.NotFound />,
              },
              {
                path: "create",
                element: <Page.CreateTraining />,
              },
              {
                path: ":trainingId",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: <Page.Training />,
                  },
                  {
                    path: "vote",
                    element: <Page.TeamTrainingVote />,
                  },
                ],
              },
              {
                path: "players",
                element: <Page.TeamTrainingPlayers />,
              },
            ],
          },

          {
            path: "competitions/",
            children: [
              {
                path: "",
                element: <Page.NotFound />,
              },
              {
                path: ":tournamentId",
                element: <Outlet />,
                children: [
                  { path: "", element: <Page.Tournament /> },
                  { path: "edit", element: <Page.EditTournament /> },
                  { path: "restart", element: <Page.RestartTournament /> },
                  { path: "player-transfer", element: <Page.PlayerTransfer /> },
                  {
                    path: "transfer",
                    element: <Page.TransferPlayer />,
                  },
                  {
                    path: "publish-group",
                    element: <Page.PublishGroupTable />,
                  },
                ],
              },
              {
                path: "setup-fixtures/:id",
                element: <Page.GenerateFixtures />,
              },
              { path: "scores/:id", element: <Page.Scores /> },
              {
                path: "create-fixtures/:id",
                element: <Page.CreateFixtures />,
              },

              {
                path: "scores/edit/:id",
                action: ScoreAction,
                element: <Page.EnterScore />,
              },
              {
                path: "scores/edit/:id/g",
                action: ScoreAction,
                element: <Page.GenInfoGraphic />,
              },
              { path: "create", element: <Page.CreateTournament /> },
            ],
          },
          {
            path: "competition-editions/",
            children: [
              {
                path: "",
                element: <Page.NotFound />,
              },
              {
                path: ":editionId",
                element: <Page.CompetitionEdition />,
              },
            ],
          },

          { path: "updates", element: <Page.Updates /> },
          { path: "feedback", element: <Page.Feedback /> },
          { path: "settings", element: <Page.Settings /> },
          { path: "post/create", element: <Page.CreatePost /> },
          { path: "post/:postId", element: <Page.Post /> },
          { path: "wallet", element: <Page.Wallet /> },
          // { path: "spotlight/create", element: <Page.CreateSpotlightPage /> },
          {
            path: "spotlight/:id",
            element: <PlayerPostsId />,
          },
          {
            path: "spotlight/player/:userId",
            element: <Page.PlayerSpotlightPage />,
          },
          {
            path: "spotlight/team/:userId",
            element: <Page.TeamSpotlightPage />,
          },
          {
            path: "spotlight/tournament/:tournamentId",
            element: <Page.TournamentSpotlightPage />,
          },
        ],
      },
      {
        path: "/account",
        element: <Outlet />,
        children: [
          { index: true, element: <Page.Login /> },
          { path: "select-profile", element: <Page.SelectProfile /> },
          { path: "login", element: <Page.Login /> },
          { path: "signup", element: <Page.SignUp /> },
          { path: "google-auth", element: <Page.GoogleAuth /> },
          { path: "forgot-password", element: <Page.ForgotPassword /> },
          {
            path: "reset-password/:resetToken",
            element: <Page.ResetPassword />,
          },
          {
            path: "final-profile/:resetToken",
            element: <Page.FinalizeAccount />,
          },

          { path: "onboarding", element: <Page.Onboarding /> },
          { path: "accept-invite", element: <Page.SplashIntro /> },
        ],
      },
      { path: "/pricing", element: <Page.Pricing /> },
      { path: "/privacy-policy", element: <Page.Privacy /> },
      { path: "/terms-of-service", element: <Page.Terms /> },
      { path: "/contact", element: <Page.Contact /> },
      { path: "/faqs", element: <Page.FAQ /> },
      { path: "/logout", element: <Page.Logout /> },
      { path: "*", element: <Page.NotFound /> },
      { path: "/404", element: <Page.NotFound /> },
      {
        path: "/payment",
        element: <Page.CompetitionCheckout />,
      },
      {
        path: "/competition/:competitionId/checkout",
        element: <Page.CompetitionCheckout />,
      },
    ],
  },
];

export default routesConfig;
