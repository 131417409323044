import { capitalize } from "../../utils/capitalize";
import formatToNaira from "../../utils/formatPrice";
import LaSpinner from "../svg-icons/LaSpinner";

export default function PaymentSummaryAndCheckOut({
  competitionNane,
  subtotal,
  vat,
  total,
  disabled,
  isLoading,
}: {
  competitionNane?: string;
  subtotal: number;
  vat: number;
  total: number;
  disabled: boolean;
  isLoading: boolean;
}) {
  return (
    <>
      <h3 className="my-4 font-bold lg:text-2xl">Payment Summary</h3>
      <p className="mb-4 font-medium lg:mb-6">{capitalize(competitionNane)}</p>
      <div className="flex flex-col gap-2 border-b border-t border-grey-light py-4">
        <div className="flex items-center justify-between">
          <p className="font-medium">Subtotal</p>
          <p className="font-bold">{formatToNaira(`${subtotal}`)}</p>
        </div>
        <div className="flex items-center justify-between">
          <abbr className="font-medium no-underline" title="Value-added Tax">
            V.A.T (7.5%)
          </abbr>
          <p className="font-bold">{formatToNaira(`${vat}`)}</p>
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between lg:mt-6">
        <p className="font-medium">Total</p>
        <p className="font-bold">{formatToNaira(`${total}`)}</p>
      </div>

      <button
        className="mt-8 flex w-full items-center justify-center gap-2 rounded bg-primary-blue-darker py-3 font-medium text-white hover:bg-primary-blue-lighter hover:text-black lg:mt-20"
        disabled={disabled}
      >
        Check Out
        {isLoading && <LaSpinner className="animate-spin" />}
      </button>
    </>
  );
}
