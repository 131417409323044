import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";

import { LaClose } from "../svg-icons";

import {
  setDisplayModal,
  setModalToDisplay,
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useEffect, useRef } from "react";
import { ArrowRightIcon, DotIcon } from "@radix-ui/react-icons";
import { GoArrowRight } from "react-icons/go";
import { Button, Spinner } from "../ui";
import { BiDotsHorizontal } from "react-icons/bi";
import { useUpdateTournamentTeamStatusMutation } from "../../redux/services/tournamentService";
import LaSpinner from "../svg-icons/LaSpinner";

export default function UpdateTeamStatusModal() {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const teamInfo = useAppSelector(
    (state: RootState) => state.tournament.updateTeamStatusInfo
  );
  const [updateStatus, { isLoading }] = useUpdateTournamentTeamStatusMutation();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    if (modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "instant", block: "end" });
    }
    return () => {
      document.body.style.overflow = "auto";
      // Revoke all file URLs to avoid memory leaks
    };
  }, []);

  function handleUpdateStatus() {
    updateStatus({
      teams: [teamInfo.teamId],
      tournamentId: teamInfo.tournamentId,
    })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        dispatch(setModalToDisplay(""));
      })
      .catch((err) => {
        if (err.status === 406) {
          dispatch(setToastErrorText("Maximum teams registered"));
        }
        dispatch(setToastError(true));
      });
  }
  return (
    <div className="absolute bottom-0 right-0 top-0 z-[100] min-h-screen w-[100vw] justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center px-4 lg:px-0">
        <div
          className="relative flex w-full flex-col rounded-xl bg-white p-4 py-8 shadow lg:w-[28%] lg:p-6 lg:py-10"
          ref={modalRef}
        >
          <h3 className="text-xl font-bold">Update Status</h3>
          <div className="mt-1 flex flex-col">
            <div className=" flex items-center justify-between">
              {teamInfo.status === "invited" ? (
                <span className="rounded-[4px] bg-yellow px-2 py-1 text-white">
                  Invited
                </span>
              ) : (
                <span className="rounded-[4px] bg-orange-600 px-2 py-1 text-white">
                  Payment Processing
                </span>
              )}
              <span className="flex items-center">
                <GoArrowRight className="h-8 w-8" />
              </span>
              <span className="rounded-[4px] bg-grey-light px-2 py-1 text-white">
                Registered
              </span>
            </div>
            <p className="mt-5 text-grey">
              In other to confirm{" "}
              <span className="font-bold capitalize text-black">
                {teamInfo.teamName}
              </span>{" "}
              tournament registration payment, Please click any of the button
              below.
            </p>
            <div className="mt-3 rounded bg-primary-blue-lighter px-2 py-[10px] text-primary-blue-dark">
              Registration Fee:{" "}
              {new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(+(teamInfo.registrationFee ?? 0))}
            </div>
            <div className="mt-3 flex flex-col gap-4 py-4">
              <Button
                intent={"primary"}
                role="organizer"
                className="w-full rounded-lg"
                onClick={handleUpdateStatus}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Payment Recieved"}
              </Button>
              <button
                className="flex w-full justify-center"
                onClick={() => dispatch(setModalToDisplay(""))}
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
