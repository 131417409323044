import { zodResolver } from "@hookform/resolvers/zod";
import type { ReactElement } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { cn } from "../../utils/cn";
import {
  LaArrowLeft,
  LaClose,
  LaDelete,
  LaEditPencil,
  LaPlusIcon,
} from "../../components/svg-icons";
import { Input } from "../../components/ui";
import {
  CreatePlayersForTeam,
  TeamCompetitionsForm,
} from "../../components/team";
import { RootState } from "../../redux/app/store";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { ConfirmPopup } from "../../components/modals";
import { useUpdateMeMutation } from "../../redux/services/userService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import RegisteredPhoneInput from "../../components/ui/input/RegisteredPhoneInput";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import HelmetComponent from "../../components/HelmetComponent";

const schema = z.object({
  name: z
    .string()
    .min(3, "name is too short")
    .max(50, "name is too long")
    .refine((name) => {
      return !name.trim().includes(" ");
    }, "name should not contain space"),
  phoneNumber: z
    .string()
    // .regex(/^[0-9]+$/, "Must be only digits")
    .max(11, "phone number is too long"),
  phonecode: z.string(),
});

export type CreatePlayersForTeamForm = z.infer<typeof schema>;

export interface CreatePlayersFormForTeamProps {}

export default function CreatePlayersFormForTeam(): ReactElement {
  const ID_LENGTH = 24;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [playersInfo, setPlayersInfo] = React.useState<
    CreatePlayersForTeamForm[]
  >([]);
  const [displayModal, setDisplayModal] = React.useState<boolean>(false);
  const [updateUser] = useUpdateMeMutation();

  const role = useAppSelector((state: RootState) => state.auth.role);
  const profile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "organizer")?.profile;
  const [selectedCompetitionForTeamId, setSelectedCompetitionForTeamId] =
    React.useState<string>("none");
  const [selectedCompetitionForTeam, setSelectedCompetitionForTeam] =
    React.useState<SelectedCompetition | null>(null);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreatePlayersForTeamForm>({
    resolver: zodResolver(schema),
  });
  const watchName = watch("name");
  const watchPhoneNumber = watch("phoneNumber");
  const watchPhonecode = watch("phonecode");

  const { data: tournament } = useGetTournamentQuery({
    id: selectedCompetitionForTeamId,
  });
  const teamProfile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "team");
  const teamId = teamProfile?.profile;

  const tournamentTeam = tournament?.teams?.find((teamObj) => {
    const team = teamObj.team as Team;
    return team._id === teamId;
  });

  const remainingPlayers =
    tournament &&
    Math.abs(
      (tournamentTeam?.players?.length ?? 0) +
        playersInfo.length -
        (tournament?.noOfPlayers ?? 0)
    );

  const onSubmit = (data: CreatePlayersForTeamForm) => {
    selectedCompetitionForTeamId.length < ID_LENGTH &&
      setSelectedCompetitionForTeamId("");

    setPlayersInfo((prev) => [...prev, data]);
    reset();
  };

  const handleDelete = (name: string) => {
    setPlayersInfo((prev) => prev.filter((player) => player.name !== name));
  };

  const handleEdit = (name: string) => {
    setPlayersInfo((prev) => prev.filter((player) => player.name !== name));
    reset({
      name: playersInfo.find((player) => player.name === name)?.name ?? "",
      phoneNumber:
        playersInfo.find((player) => player.name === name)?.phoneNumber ?? "",
      phonecode:
        playersInfo.find((player) => player.name === name)?.phonecode ?? "",
    });
  };

  function handleSwitchProfile() {
    if (!profile) return navigate("/account/onboarding?role=team&step=2");
    updateUser({ role: "organizer" })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setDisplayModal(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  React.useEffect(() => {
    if (role !== "team") {
      setDisplayModal(true);
    }
  }, [role, profile]);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Players",
    description: "Create players for your teams on Leagues Arena",
    url: `https://${window.location.host}/discover/create-players`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center justify-center pb-10">
        <div className="w-full lg:max-w-[87%]">
          <div
            className={cn("pb-24 pt-4 lg:pb-0", {
              "pb-40 lg:pb-40": playersInfo.length,
            })}
          >
            <div className="px-4">
              <div className="flex items-center gap-4 py-4 lg:px-0">
                <button
                  aria-label="back"
                  className="text-xl lg:hidden"
                  onClick={() => navigate(-1)}
                >
                  <LaArrowLeft />
                </button>
                <h3 className="text-xl font-bold">Register players</h3>
              </div>
              <p>
                Register players to your team by providing the details below.
                Players will be notified to complete profile set up.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center px-4 lg:items-start lg:px-0 lg:pl-4">
              <form
                action=""
                className="mt-6 flex flex-col gap-2  md:w-[71%] lg:w-[60%]"
                onSubmit={handleSubmit(onSubmit)}
              >
                <fieldset>
                  <label htmlFor="name" className="text-sm md:text-base">
                    Player Name<span className="text-red">*</span>
                  </label>
                  <Input
                    role="organizer"
                    type="text"
                    id="name"
                    register={register}
                    name={"name"}
                    placeholder="Enter player name"
                    required
                    className={cn(
                      "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-opacity-30 lg:text-xl",
                      {
                        "error border-red focus:border-red": errors.name,
                      }
                    )}
                  />
                  {errors.name && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.name.message}
                    </span>
                  )}
                </fieldset>

                <fieldset>
                  <label htmlFor="phone" className="text-sm md:text-base">
                    Player's phone number{" "}
                    <span className="text-grey">(optional)</span>
                  </label>
                  <RegisteredPhoneInput
                    name="phoneNumber"
                    error={errors.phoneNumber?.message}
                    phonecodeName={"phonecode"}
                    register={register}
                    // required="Phone number is required"
                    setValue={setValue}
                    id="phone-number"
                  />

                  {errors.phoneNumber && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </fieldset>
                <div>
                  <fieldset className="mt-2 flex items-center justify-between">
                    <button
                      type="button"
                      className="flex items-center gap-2 text-red"
                      onClick={() => navigate(-1)}
                    >
                      <span className="flex h-4 w-4 items-center justify-center rounded-full border border-red">
                        <LaClose className="text-[8px]" stroke="#f00" />
                      </span>
                      Cancel
                    </button>
                    <button
                      className="flex items-center gap-2 text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-50"
                      disabled={playersInfo.some(
                        (player) =>
                          (!remainingPlayers && tournament) ||
                          player.name === watchName ||
                          (`${player.phoneNumber}` === `${watchPhoneNumber}` &&
                            player.phoneNumber !== "")
                      )}
                    >
                      <LaPlusIcon />
                      Register
                      {playersInfo.length > 0 && " another player"}
                    </button>
                  </fieldset>
                  {!remainingPlayers && tournament && (
                    <p className="text-sm text-red">
                      You have reached the maximum number of players allowed by
                      the organizer
                    </p>
                  )}
                </div>
              </form>
              <div className="mt-6 flex max-h-[400px] w-full  flex-col gap-2 overflow-y-scroll md:w-[71%] lg:w-[60%]">
                {playersInfo.map((player) => {
                  return (
                    <div
                      className="flex items-center justify-between rounded bg-primary-black-lighter p-4"
                      key={player.name}
                    >
                      <div className="text-sm">
                        <p className="font-bold">{player.name}</p>
                        {player.phoneNumber && (
                          <p>
                            {player.phonecode} {player.phoneNumber}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleEdit(player.name)}
                          type="button"
                        >
                          <LaEditPencil />
                        </button>
                        <button
                          onClick={() => handleDelete(player.name)}
                          type="button"
                        >
                          <LaDelete />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {playersInfo.length > 0 &&
        selectedCompetitionForTeamId.length === ID_LENGTH && (
          <CreatePlayersForTeam
            playersInfo={playersInfo}
            setPlayersInfo={setPlayersInfo}
            selectedCompetitionForTeamId={selectedCompetitionForTeamId}
            selectedCompetitionForTeam={selectedCompetitionForTeam}
          />
        )}
      {!selectedCompetitionForTeamId && (
        <TeamCompetitionsForm
          setSelectedCompetitionForTeamId={setSelectedCompetitionForTeamId}
          setSelectedCompetitionForTeam={setSelectedCompetitionForTeam}
        />
      )}

      {displayModal && (
        <ConfirmPopup
          heading={profile ? "Switch your profile" : "Create a team profile"}
          type="warning"
          description={`You need to ${
            profile ? "switch your profile" : "create a team profile"
          } to perform this action`}
          buttons={[
            {
              text: "Proceed",
              handleClick: handleSwitchProfile,
              class: "primary",
            },
            {
              text: "Cancel",
              handleClick: () => {
                setDisplayModal(false);
                navigate(-1);
              },
              class: "secondary",
            },
          ]}
        />
      )}
    </>
  );
}
