import { useEffect, useState } from "react";
import { cn } from "../../utils/cn";
import { Button } from "../ui";
import { LaCaretDown, LaCaretUp, LaCheck } from "../svg-icons";
import { Link } from "react-router-dom";
import SquadInfoModal from "../modals/SquadInfoModal";
import { useRemoveTeamsMutation } from "../../redux/services/tournamentService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch } from "../../redux/app/hooks";
import { FaEllipsis, FaEllipsisVertical } from "react-icons/fa6";
import ManageTeamsActionDropDown from "./ManageTeamsActionDropDown";

interface ManageTeamsProps {
  teamData: Tournament["teams"];
  tournamentId: string;
  tournamentName: string;
  isTournamentOwner: boolean;
  registrationFee?: string;
}

type TTabs = "all" | "registered" | "payment processing" | "invited";
type TActions = "delete teams";

export default function ManageTeams({
  teamData,
  tournamentId,
  tournamentName,
  isTournamentOwner,
  registrationFee,
}: ManageTeamsProps) {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TTabs>("all");
  const [actionsOpen, setActionsOpen] = useState(false);
  const [clickedTeamData, setClickedTeamData] = useState<
    Tournament["teams"][0] | undefined
  >();
  const [removeTeams] = useRemoveTeamsMutation();
  const clickedTeam = clickedTeamData?.team as Team;
  const registered = teamData.filter(
    (teamData) => teamData?.registered === true
  );
  const invited = teamData.filter((teamData) => teamData?.status === "invited");
  const paymentProcessing = teamData.filter(
    (teamData) => teamData?.status === "paymentProcessing"
  );
  const tableData = teamData?.filter((data) => {
    const filters = {
      all: true,
      registered: data?.registered,
      "payment processing": data.status === "paymentProcessing",
      invited: data.status === "invited",
    };
    return filters[activeTab];
  });

  return (
    <>
      <div className="my-4 flex items-center gap-4 lg:mt-8">
        <div className="modern__scroll-bar flex items-center gap-4 overflow-x-scroll">
          {tabs.map((tab) => (
            <Button
              key={tab}
              intent="secondary"
              role={"organizer"}
              className={cn(
                "min-w-max rounded-full border border-grey-light py-2 capitalize hover:bg-white",
                {
                  "border-primary-black-darker": activeTab === tab,
                }
              )}
              onClick={() => setActiveTab(tab)}
            >
              {tab}{" "}
              <span className="flex h-5 w-5 items-center justify-center rounded-full bg-[#D8D8D8] text-[10px]">
                {tab === "all" && teamData.length}
                {tab === "registered" && registered.length}
                {tab === "payment processing" && paymentProcessing.length}
                {tab === "invited" && invited.length}
              </span>
            </Button>
          ))}
        </div>
      </div>
      <div className="overflow-x-scroll">
        <div className="min-w-max rounded border border-grey-light">
          <ul
            className={cn(
              "grid w-full min-w-max grid-cols-3 gap-4 bg-primary-black-lighter px-4 py-2 lg:gap-0",
              {
                "grid-cols-4": isTournamentOwner,
              }
            )}
          >
            <li className="flex items-center gap-4">Teams</li>
            {tableHeadings.map((heading) => {
              if (heading === "Action" && !isTournamentOwner) {
                return null;
              }

              return (
                <li
                  className="min-w-max text-center lg:place-self-center"
                  key={heading}
                >
                  {heading}
                </li>
              );
            })}
          </ul>
          <ul className="w-full min-w-max">
            {!tableData.length ? (
              <li className="p-4 text-center">No teams</li>
            ) : (
              tableData.map((data) => {
                const team = data.team as Team;
                return (
                  <>
                    <li
                      key={team?._id}
                      className={cn(
                        "relative grid grid-cols-3 border-b border-grey-light p-4",
                        {
                          "grid-cols-4": isTournamentOwner,
                        }
                      )}
                    >
                      <div className="flex items-center gap-4">
                        <img
                          className="h-6 w-6 rounded-full bg-grey-light"
                          src={team?.avatar}
                        />
                        <div>
                          <h3 className="font-bold capitalize text-primary-black-darker">
                            {team.name}
                          </h3>
                          <button
                            className={cn(" pr-2 text-xs", {
                              "border-0": !isTournamentOwner,
                            })}
                            onClick={() => setClickedTeamData(data)}
                          >
                            Squad info
                          </button>
                          {/* {isTournamentOwner && (
                            <Link
                              to={
                                data?.registered
                                  ? `/competitions/${tournamentId}/player-transfer`
                                  : "?tab=teams"
                              }
                              className="pl-2 text-xs"
                            >
                              Player transfer
                            </Link>
                          )} */}
                        </div>
                      </div>
                      <p className="place-self-center">
                        {data?.players?.length}
                      </p>
                      <p
                        className={cn(
                          "place-self-center rounded px-3 py-1 text-sm  text-white",
                          {
                            "bg-green": data.registered,
                            "bg-yellow": data.status === "invited",
                            "bg-orange-600":
                              data.status === "paymentProcessing",
                          }
                        )}
                      >
                        {data.registered
                          ? "Registered"
                          : data?.status === "paymentProcessing"
                          ? "Payment Processing"
                          : "Invited"}
                      </p>
                      {isTournamentOwner && (
                        <ManageTeamsActionDropDown
                          registrationFee={registrationFee ?? ""}
                          teamId={team._id}
                          teamName={team.name}
                          tournamentId={tournamentId}
                          status={data?.status}
                          registered={data.registered}
                        />
                      )}
                    </li>
                  </>
                );
              })
            )}
          </ul>
        </div>
      </div>
      {clickedTeamData && (
        <SquadInfoModal
          draws={clickedTeamData?.draws}
          losses={clickedTeamData?.losses}
          played={clickedTeamData?.played}
          setClickedTeamData={setClickedTeamData}
          teamId={clickedTeam?._id}
          teamName={clickedTeam?.name}
          tournamentId={tournamentId}
          tournamentName={tournamentName}
          wins={clickedTeamData?.wins}
        />
      )}
    </>
  );
}

const tabs: Array<TTabs> = [
  "all",
  "registered",
  "payment processing",
  "invited",
];

const actions: Array<TActions> = ["delete teams"];
const tableHeadings = ["Registered Players", "Registration Status", "Action"];
