import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const tournamentApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyTournaments: builder.query<Tournament[] | undefined, {}>({
      query: (type) => ({
        url: "/tournaments/me",
        method: "GET",
        params: {
          type: type,
        },
      }),
      transformResponse: (response: { data: Tournament[] }) => response.data,
      providesTags: ["Tournament"],
    }),
    getUserTournaments: builder.query<
      Tournament[] | undefined,
      { userId?: string; query?: { deep?: boolean } }
    >({
      query: ({ userId, query }) => ({
        url: `/tournaments/author/${userId}`,
        method: "GET",
        params: {
          deep: query?.deep ?? false,
        },
      }),
      transformResponse: (response: { data: Tournament[] }) => response.data,
      providesTags: ["Tournament"],
    }),
    getTournaments: builder.query<
      Tournament[] | undefined,
      { page?: number; orderBy?: string; count?: number }
    >({
      query: (arg) => ({
        url: `/tournaments/`,
        method: "GET",
        params: { ...arg },
      }),
      transformResponse: (response: { data: Tournament[] }) => response.data,
      providesTags: ["Tournament"],
    }),
    getTournament: builder.query<Tournament | undefined, { id?: string }>({
      query: ({ id }) => ({
        url: `/tournaments/${id}`,
        method: "GET",
      }),
      transformResponse: (response: { data: Tournament }) => response.data,
      providesTags: ["Tournament"],
    }),
    deleteTournament: builder.mutation({
      query: ({ id }) => ({
        url: `/tournaments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tournament"],
    }),
    searchTournaments: builder.query<Tournament | undefined, { name: string }>({
      query: ({ name }) => {
        return {
          method: "GET",
          url: `/tournaments/name/${name}`,
        };
      },
      transformResponse: (response: { data: Tournament }) => response.data,
    }),
    createTournament: builder.mutation({
      query: (body) => ({
        url: "/tournaments/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament"],
    }),
    acceptTournamentInvite: builder.mutation({
      query: (body) => ({
        url: "/tournaments/team/invite/accept",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament", "Team", "Organizer"],
    }),
    declineTournamentInvite: builder.mutation({
      query: (body: { tournamentId: string }) => ({
        url: "/tournaments/team/invite/decline",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament", "Team", "Organizer"],
    }),
    sendTournamentInvites: builder.mutation({
      query: (body) => ({
        url: "/tournaments/team/invite/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament", "Team"],
    }),
    sendTournamentRequest: builder.mutation({
      query: (body: { players: string[]; tournamentId: string }) => ({
        url: "/tournaments/team/request/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament", "Team"],
    }),
    acceptTournamentRequest: builder.mutation({
      query: (body: { teamId: string; tournamentId: string }) => ({
        url: "/tournaments/team/request/accept",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Team", "Tournament", "Organizer"],
    }),
    declineTournamentRequest: builder.mutation({
      query: (body: { teamId: string; tournamentId: string }) => ({
        url: "/tournaments/team/request/decline",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Team", "Tournament", "Organizer"],
    }),
    updateTournamentStats: builder.mutation({
      query: (body) => {
        return {
          url: `/tournaments/stats/update/${body.id}`,
          method: "POST",
          body,
        };
      },
      // invalidatesTags: ["POST", "Tournament"],
      invalidatesTags: (result) => {
        return [
          { type: "Posts", id: result?.data?._id },
          { type: "Posts", id: "PARTIAL-LIST" },
          { type: "Tournament", id: result?.data?._id },
          { type: "Tournament", id: "PARTIAL-LIST" },
        ];
      },
    }),
    updateTournament: builder.mutation({
      query: (args: { tournamentId: string; body: FormData }) => ({
        url: `/tournaments/${args.tournamentId}`,
        method: "PUT",
        body: args.body,
      }),
      invalidatesTags: ["Tournament", "Match"],
    }),
    removeTeam: builder.mutation({
      query: (body) => ({
        url: `/tournaments/team/remove`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tournament"],
    }),
    removeTeams: builder.mutation({
      query: (body) => ({
        url: "/tournaments/teams/remove",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tournament"],
    }),
    getTeamTournaments: builder.query<
      Tournament[] | undefined,
      { teamId?: string }
    >({
      query: ({ teamId }) => ({
        url: `/tournaments/team/${teamId}`,
        method: "GET",
      }),
      providesTags: ["Tournament"],
      transformResponse: (response: { data: Tournament[] }) => response.data,
    }),
    canJoinTournament: builder.mutation({
      query: (body: { tournamentId: string; players: string[] }) => ({
        url: "tournaments/can-join",
        method: "POST",
        body,
      }),
    }),
    createEdition: builder.mutation({
      query: (body) => ({
        url: "tournaments/editions",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament"],
    }),
    getEditions: builder.query<TEdition[] | undefined, void>({
      query: () => ({
        url: `/tournaments/editions`,
        method: "GET",
      }),
      // transformResponse: (response: { data: TEdition[] }) => response.data,
      providesTags: ["Tournament"],
    }),
    getEdition: builder.query<TEdition | undefined, { id?: string }>({
      query: ({ id }) => ({
        url: `/tournaments/editions/${id}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: TEdition }) => response.data,
      providesTags: ["Tournament"],
    }),
    addTeams: builder.mutation({
      query: (body) => ({
        url: `/tournaments/add-teams`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament", "Team"],
    }),
    updateTournamentTeamStatus: builder.mutation({
      query: (body) => ({
        url: `/tournaments/update-team-status`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament"],
    }),
    registerForTournament: builder.mutation({
      query: (body) => ({
        url: "/tournaments/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tournament"],
    }),
    submitGroupFixture: builder.mutation({
      query: (args: {
        tournamentId: string;
        fixture: Record<string, string>;
      }) => ({
        url: `/tournaments/group/set_group_table`,
        method: "POST",
        body: { fixture: args.fixture, tournamentId: args.tournamentId },
      }),
      invalidatesTags: ["Tournament", "Match"],
    }),
    readGroupTable: builder.query<any, { tournamentId: string }>({
      query: ({ tournamentId }) => ({
        url: `/tournaments/group/table?tournamentId=${tournamentId}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Tournament[] }) => response.data,
      providesTags: ["Tournament"],
    }),
  }),
});

export const {
  useGetMyTournamentsQuery,
  useDeleteTournamentMutation,
  useGetTournamentsQuery,
  useGetTournamentQuery,
  useSearchTournamentsQuery,
  useCreateTournamentMutation,
  useAcceptTournamentInviteMutation,
  useSendTournamentInvitesMutation,
  useDeclineTournamentInviteMutation,
  useSendTournamentRequestMutation,
  useGetUserTournamentsQuery,
  useAcceptTournamentRequestMutation,
  useDeclineTournamentRequestMutation,
  useUpdateTournamentStatsMutation,
  useUpdateTournamentMutation,
  useRemoveTeamMutation,
  useGetTeamTournamentsQuery,
  useCanJoinTournamentMutation,
  useCreateEditionMutation,
  useGetEditionsQuery,
  useGetEditionQuery,
  useAddTeamsMutation,
  useRegisterForTournamentMutation,
  useSubmitGroupFixtureMutation,
  useRemoveTeamsMutation,
  useReadGroupTableQuery,
  useUpdateTournamentTeamStatusMutation,
} = tournamentApi;
