import type { ReactElement } from "react";
import { LaArrowLeft, Search } from "../../components/svg-icons";
import { cn } from "../../utils/cn";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/ui";
import { InviteForm } from "../../components/discover";
import { useUpdateMeMutation } from "../../redux/services/userService";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { RootState } from "../../redux/app/store";
import { ConfirmPopup } from "../../components/modals";
import HelmetComponent from "../../components/HelmetComponent";
import { useSearchTeamByKeywordQuery } from "../../redux/services/teamService";
import { useDebounce } from "use-debounce";
import { BiPlus } from "react-icons/bi";
import { AddTeamsToCompetition } from "../../components/tournament";

export default function SearchAndAddTeamsPage(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateUser] = useUpdateMeMutation();
  const [displayModal, setDisplayModal] = React.useState<boolean>(false);
  const role = useAppSelector((state: RootState) => state.auth.role);
  const profile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "organizer")?.profile;

  const [selectedCompetition, setSelectedCompetition] =
    React.useState<string>("none");
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
  const [maxTeam, setMaxTeam] = React.useState<number>(0);
  const [competitionTeams, setCompetitionTeams] = React.useState<
    Tournament["teams"]
  >([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [isResultsOpen, setIsResultsOpen] = useState(false);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const isDebouncing = searchQuery !== debouncedSearchQuery;
  const { data, isFetching } = useSearchTeamByKeywordQuery(
    debouncedSearchQuery,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  function handleSwitchProfile() {
    if (!profile) return navigate("/account/onboarding?role=organizer&step=2");
    updateUser({ role: "organizer" })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setDisplayModal(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  React.useEffect(() => {
    if (role !== "organizer") {
      setDisplayModal(true);
    }
  }, [role, profile]);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Teams",
    description: "Add teams for your competition on Leagues Arena",
    url: `https://${window.location.host}/discover/add-teams`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  const renderLoading = (numberOfItems: number = 3) => {
    const items = Array.from({ length: numberOfItems }, (_, i) => i);
    return items.map((_, i) => (
      <div className="flex items-center gap-[10px] p-[10px]" key={i}>
        <div className="h-10 w-10 animate-pulse rounded-full bg-grey-light" />
        <div className="h-4 w-32 animate-pulse rounded-sm bg-grey-light" />
        <div />
      </div>
    ));
  };

  const renderNoResults = () => {
    if (searchQuery) {
      return (
        <div className="flex min-h-[56px] items-center justify-between gap-[10px] p-[10px]">
          <span>Search not found</span>
          <Link
            to="/discover/create-teams"
            className="flex items-center gap-1 text-primary-blue-dark"
          >
            <BiPlus />
            Add manually
          </Link>
        </div>
      );
    }
    return null;
  };

  const renderTeams = () =>
    data?.map((team) => (
      <div
        className="flex min-h-[56px] cursor-pointer items-center gap-[10px] p-[10px]"
        key={team._id}
        onClick={() => {
          setSelectedCompetition("");
          setSelectedTeams([...selectedTeams, team._id]);
        }}
      >
        <img
          src={team.avatar}
          className="h-10 w-10 rounded-full"
          alt={team.name}
        />
        <span className="capitalize">{team.name}</span>
      </div>
    ));

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center justify-center pb-10">
        <div className="w-full lg:max-w-[87%]">
          <div className="pb-24 pt-4 lg:pb-0">
            <div className="px-4 lg:px-0">
              <div className="flex items-center gap-4 py-4 lg:px-0">
                <button
                  aria-label="back"
                  className="text-xl lg:hidden"
                  onClick={() => navigate(-1)}
                >
                  <LaArrowLeft />
                </button>
                <h3 className="text-xl font-bold">Add team</h3>
              </div>
              <p>
                Add a new team by searching with the name team or phone number
                below. If the team is available in the system, click the team to
                add it.
              </p>
              <p>
                Can’t find team? Click “add new team”. The contact person will
                be notified to complete team set up
              </p>
            </div>
            <div className="flex flex-col px-4 lg:px-0">
              <div className="relative mt-8 w-full py-2 md:w-[60%] lg:w-[40%]">
                <Input
                  name="search"
                  role="organizer"
                  placeholder="Team name or phone number"
                  className="m-0 pl-10"
                  value={searchQuery}
                  onFocus={() => setIsResultsOpen(true)}
                  onChange={(e) => setSearchQuery(e.target.value)}
                ></Input>
                <div className="absolute left-[3%] top-1/2 -translate-y-1/2 transform ">
                  <Search />
                </div>
                {isResultsOpen && (
                  <div className="absolute top-[90%] flex max-h-80 w-full flex-col divide-y divide-grey-light overflow-y-scroll rounded-sm border shadow-md">
                    {isFetching || isDebouncing
                      ? renderLoading(3)
                      : data &&
                        (data.length === 0 ? renderNoResults() : renderTeams())}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!selectedCompetition && (
        <InviteForm
          setSelectedCompetition={setSelectedCompetition}
          setMaxTeam={setMaxTeam}
          setCompetitionTeams={setCompetitionTeams}
        />
      )}

      {selectedTeams.length > 0 && selectedCompetition.length > 4 && (
        <AddTeamsToCompetition
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
          setCompetitionTeams={setCompetitionTeams}
          setSelectedCompetition={setSelectedCompetition}
          selectedCompetition={selectedCompetition}
          shouldScroll={true}
          maxTeam={maxTeam}
          noOfTeamsJoined={competitionTeams.length}
        />
      )}

      {displayModal && (
        <ConfirmPopup
          heading={
            profile ? "Switch your profile" : "Create an organizer profile"
          }
          type="warning"
          description={`You need to ${
            profile ? "switch your profile" : "create an organizer profile"
          } to perform this action`}
          buttons={[
            {
              text: "Proceed",
              handleClick: handleSwitchProfile,
              class: "primary",
            },
            {
              text: "Cancel",
              handleClick: () => {
                setDisplayModal(false);
                navigate(-1);
              },
              class: "secondary",
            },
          ]}
        />
      )}
    </>
  );
}
